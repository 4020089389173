import React from 'react';
import { AvailableTicket, TicketType } from '@/lib/OrderApi';

interface TapConnectTicketProps {
  ticket: AvailableTicket;
}

const TapConnectTicket: React.FC<TapConnectTicketProps> = ({ ticket }) => {
  // Safety check: Make sure this is a TapConnect ticket
  if (ticket.ticketType !== TicketType.TapConnect) {
    console.error('Invalid ticket type passed to TapConnectTicket component');
    return <div>Invalid ticket type</div>;
  }
  
  return (
    <>
      <div className='text-center mx-auto text-[var(--shop-textColor)]'>
        {ticket.tapConnectServiceId}
      </div>
      <img 
        src={`data:image/png;base64,${ticket.aztecBase64Png}`}
        alt="Aztec Code"
        className='mx-auto max-w-[200px] p-4 bg-white my-6'
      />
    </>
  );
};

export default TapConnectTicket; 