import React, { useEffect, useState, useRef } from 'react';
import { ProductPage as ProductPageType } from '@/lib/TicketApi';
import ProductSection from './ProductSection';
import useQueue from '@/hooks/useQueue';
import { useShop } from '@/providers/ShopProvider';
import { QueueContextProvider } from '@/providers/QueueContext';
import { QueueOverlay } from '@/components/queue/QueueOverlay';
import { eventEmitter } from '@/lib/eventEmitter';
import { shopVar } from '@/lib/shopStyles';
import clsx from 'clsx';
import { playNotificationSound } from '@/assets/notification';

interface ProductPageProps {
  productPage: ProductPageType;
}

export const ProductPage: React.FC<ProductPageProps> = ({ productPage }) => {
  const { shop } = useShop();
  const { queueStatus } = useQueue(shop.queueKey);
  const {isWidget} = useShop();
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const previousAccessRef = useRef<boolean>();

  // Update queue context when status changes
  useEffect(() => {
    const isQueueActive = !(!shop.queueKey || !queueStatus || queueStatus.data.allowed_access);
    QueueContextProvider.updateQueueState(isQueueActive);

    // When queue access is granted, refresh shop data to get latest product availability
    if (queueStatus?.data.allowed_access) {
      eventEmitter.emit('RELOAD_SHOP_CONFIG', {
        shopSlug: shop.slug,
      });

      // Only notify if access was just granted (changed from false to true)
      const hadPreviousAccess = previousAccessRef.current;
      if (notificationsEnabled && hadPreviousAccess === false) {
        // Play notification sound
        playNotificationSound();

        // Show browser notification
        if (Notification.permission === 'granted') {
          const eventName = shop.events[0]?.name || 'the event';
          const notification = new Notification('Your turn! 🎉', {
            body: `You can now access the shop and buy tickets for ${eventName}. You have 10 minutes to make your purchase.`,
            icon: 'https://cdn.prod.website-files.com/66bc85afc94c2274121979c7/66bc976f0d0cd70b55ff38fa_fafvicon.png',
          });

          // Focus the window when notification is clicked
          notification.onclick = () => {
            window.focus();
            notification.close();
          };
        }
      }
    }

    // Update previous access state
    previousAccessRef.current = queueStatus?.data.allowed_access;
  }, [shop.queueKey, queueStatus, shop.slug, notificationsEnabled]);

  if(shop.queueKey && queueStatus === null) {
    // show loading indicator
    return (<div 
      className={clsx('py-2 px-8 rounded-md justify-between items-center', isWidget ? 'mt-4' : '')}
      style={{backgroundColor: shopVar('--shop-panelBackgroundColor')}}>
      <div className="flex animate-pulse space-x-4">
        <div className="size-10 rounded-full bg-gray-200"></div>
        <div className="flex-1 space-y-6 py-1">
          <div className="h-2 rounded bg-gray-200"></div>
          <div className="space-y-3">
            <div className="grid grid-cols-3 gap-4">
              <div className="col-span-2 h-2 rounded bg-gray-200"></div>
              <div className="col-span-1 h-2 rounded bg-gray-200"></div>
            </div>
            <div className="h-2 rounded bg-gray-200"></div>
          </div>
        </div>
      </div>
    </div>)
  }
  // If we're in queue, show the queue overlay
  if (shop.queueKey && queueStatus && !queueStatus.data.allowed_access) {
    return (
      <div 
        className={clsx('py-2 px-8 rounded-md justify-between items-center', isWidget ? 'mt-4' : '')}
        style={{backgroundColor: shopVar('--shop-panelBackgroundColor')}}>
          <QueueOverlay 
            queueStatus={queueStatus} 
            onNotificationsEnabled={() => setNotificationsEnabled(true)}
            notificationsEnabled={notificationsEnabled}
          />
      </div>
    );
  }

  // Show normal product sections when no queue or we have access
  return (
    <>
      {productPage.productSections.map((productSection) => (
        <ProductSection key={productSection.id} productSection={productSection} />
      ))}
    </>
  );
};