import { useShop } from '@/providers/ShopProvider';
import { useShopStyle } from '@/providers/StyleOverwrites';
import { format } from 'date-fns';
import clsx from 'clsx';
import { Link } from '@tanstack/react-router';
import { LanguageSelect } from '../LanguageSelect';
import { RiCloseLine } from "@remixicon/react";
import { FastlaneLogo } from '../shared/FastlaneLogo';
import { shopVar } from '@/lib/shopStyles';
import { useEffect, useState } from 'react';

interface EventIndexProps {
  shopSlug: string;
}

export function EventIndex({ shopSlug }: EventIndexProps) {
  const { shop, isWidget, isStandalone, setSelectedEventSlug } = useShop();
  const shopStyle = useShopStyle();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 768);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);
  

  const handleEventSelect = (eventSlug: string) => {
    setSelectedEventSlug(eventSlug);
  };

  const handleClose = () => {
    window.dispatchEvent(new CustomEvent('shop-close', { detail: { shopSlug } }));
  };

  if (!shop || shop.events.length === 0) {
    return null;
  }

  // If there's only one event, don't show the index
  if (shop.events.length === 1) {
    if(isStandalone) {
      window.location.href = `/${shopSlug}/${shop.events[0].slug}`;
    }
    return null;
  }

  const firstEvent = shop.events[0];

  const renderEventCard = (event: { slug: string; name: string; startDate?: string }) => (
    <div
      className={clsx('py-6 px-8 rounded-md transition-transform hover:scale-[1.01]', isWidget ? 'mt-4' : '')}
      style={{
        backgroundColor: shopStyle.panelBackgroundColor,
        border: isWidget ? undefined : `1px solid ${shopStyle.borderColor}`,
        color: shopStyle.textColor,
      }}
    >
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-xl font-semibold pr-2">{event.name}</h2>
          {event.startDate && (
            <p className="text-sm opacity-80">
              {format(new Date(event.startDate), 'MMMM d, yyyy')}
            </p>
          )}
        </div>
        <div
          className="px-6 py-2 rounded text-sm font-medium shrink-0"
          style={{
            backgroundColor: shopStyle.ctaButtonColor,
            color: shopStyle.ctaButtonTextColor,
          }}
        >
          Go to event
        </div>
      </div>
    </div>
  );

  const EventCardWrapper = ({ event }: { event: { slug: string; name: string; startDate?: string } }) => {
    if (isStandalone) {
      return (
        <Link
          key={event.slug}
          to="/$slug/$event"
          params={{
            slug: shopSlug,
            event: event.slug
          }}
          className="block cursor-pointer"
          onClick={() => handleEventSelect(event.slug)}
        >
          {renderEventCard(event)}
        </Link>
      );
    }

    return (
      <article
        key={event.slug}
        onClick={() => handleEventSelect(event.slug)}
        className="cursor-pointer"
      >
        {renderEventCard(event)}
      </article>
    );
  };

  return (
    <div className={`min-w-[252px] md:min-w-[552px] font-sans ${isWidget ? 'rounded-md' : 'rounded-3xl'}`}
      style={{
        color: shopVar('--shop-textColor'),
        backgroundColor: isMobile || !isWidget ? shopVar('--shop-panelBackgroundColor') : undefined
      }}>
      <div 
        className={`relative p-4 bg-cover bg-center bg-no-repeat h-28 w-full ${isWidget ? 'md:rounded-md md:rounded-b-none' : 'md:rounded-3xl md:rounded-b-none'}`}
        style={{ backgroundImage: `url(${firstEvent.headerImage})` }}
      >
        {isWidget && (
          <div 
            className='absolute top-6 right-6 p-2 hover:cursor-pointer hover:bg-gray-100/10 rounded-full hover:scale-105 transition-transform duration-200' 
            onClick={handleClose}
          >
            <RiCloseLine size={30} color={'black'}/>
          </div>
        )}
      </div>
      <div className={clsx('py-4 flex items-center rounded-md rounded-t-none justify-between px-8')} style={{backgroundColor: shopStyle.panelBackgroundColor}}>
        <div className="text-xl font-bold">Events</div>
        <LanguageSelect />
      </div>
      <div className={clsx('space-y-4', isWidget ? '' : 'px-8 py-4')}>
        {shop.events.map((event) => (
          <EventCardWrapper key={event.slug} event={event} />
        ))}
      </div>
      <div className='flex justify-center pb-4'>
        <FastlaneLogo />
      </div>
    </div>
  );
} 
