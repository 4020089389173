import React, { useState } from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { MoneyInput } from '@/components/MoneyInput';
import { Money } from '@/lib/money';
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import FastlaneLogo from '/assets/fastlane_logo.svg';
import idealStatement from '/assets/images/ideal-statement.png';
import cardLookup from '/assets/images/card-lookup.png';
import { InputOTP, InputOTPGroup, InputOTPSlot } from '@/components/ui/input-otp';

type PaymentMethod = 'ideal' | 'creditcard' | 'bancontact';

interface LookupResponse {
    payments: Array<{
        orderUrl: string;
    }>;
}

interface ValidationError {
    message: string;
    errors: {
        [key: string]: string[];
    };
}

interface LookupPayload {
    type: PaymentMethod;
    iban?: string;
    description?: string;
    issuerBin?: string;
    lastFourDigits?: string;
    date?: string;
    amountInCents?: number;
}

export const Route = createFileRoute('/lookup-order')({
    component: LookupOrder
});

function LookupOrder() {
    const navigate = Route.useNavigate();
    const [error, setError] = useState<string | null>(null);
    const [validationErrors, setValidationErrors] = useState<ValidationError | null>(null);
    const [foundOrders, setFoundOrders] = useState<LookupResponse | null>(null);
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>('ideal');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        iban: '',
        description: '',
        issuerBin: '000000',
        lastFourDigits: '',
        date: '',
        amount: Money.fromInteger(0, 'EUR'),
    });

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const payload: LookupPayload = { type: paymentMethod };

        switch (paymentMethod) {
            case 'ideal':
                payload.iban = formData.iban;
                payload.description = formData.description;
                break;
            case 'creditcard':
                payload.issuerBin = formData.issuerBin;
                payload.lastFourDigits = formData.lastFourDigits;
                payload.date = formData.date;
                payload.amountInCents = formData.amount.amount;
                break;
            case 'bancontact':
                payload.iban = formData.iban;
                payload.date = formData.date;
                payload.amountInCents = formData.amount.amount;
                break;
        }

        try {
            setError(null);
            setValidationErrors(null);
            setIsSubmitting(true);
            const response = await fetch(`${import.meta.env.VITE_API_URL}/payment-lookup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify(payload),
            });

            const result = await response.json();

            if (!response.ok) {
                if (response.status === 422) {
                    setValidationErrors(result);
                    return;
                }
                if (response.status === 429) {
                    setError('Je hebt te veel pogingen gedaan. Wacht een paar minuten en probeer het opnieuw of neem contact op met support.');
                    return;
                }
                throw new Error('Failed to lookup order');
            }

            if (result.payments.length === 0) {
                setError('No orders found matching your criteria');
                return;
            }

            if (result.payments.length === 1) {
                const orderUrl = new URL(result.payments[0].orderUrl);
                const pathParts = orderUrl.pathname.split('/');
                const orderId = pathParts[pathParts.length - 1];
                navigate({ to: '/order/$orderId', params: { orderId } });
                return;
            }

            setFoundOrders(result);
        } catch (error: unknown) {
            console.error('Lookup error:', error);
            setError('Failed to lookup order. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const getFieldError = (fieldName: string) => {
        if (!validationErrors?.errors) return null;
        return validationErrors.errors[fieldName]?.[0];
    };

    return (
        <div className="container mx-auto py-8">
            <div className="flex justify-center">
                <img src={FastlaneLogo} alt="Logo" className="w-60 mb-8" />
            </div>

            <div className="mx-auto p-6 max-w-7xl">
                {error && (<>
                    <div className="mt-4 p-4 text-lg font-bold bg-red-200 text-red-700 rounded-t-lg shadow-lg border-red-300 border-4 border-b-0">
                        {error}
                    </div>
                    <div className="mb-6 text-lg bg-red-50 border-red-300 p-4 rounded-b-lg border-t-0 border-4 shadow-lg">
                        When you fail to find your order, please contact us at <a href="mailto:support@fastlane.events">support@fastlane.events</a> and we will help you out.
                        Please include the name of the event, and a screenshot of the payment.
                    </div>
                    </>
                )}
                <Tabs defaultValue={'ideal'} value={paymentMethod} onValueChange={(value: string) => setPaymentMethod(value as PaymentMethod)}>
                    <div className="flex justify-between">
                        <div className="lg:pr-6">
                            <h2 className="text-2xl font-bold mb-6">Tickets kwijt?</h2>
                            <div className="mb-6 text-lg">
                                Via deze pagina kun je je bestelling vinden. Hiervoor heb je gegevens nodig van je betaling.
                                Selecteer de betalingsmethode die je gebruikt hebt.
                            </div>
                            <TabsList className="mb-4 w-full flex justify-between">
                                <TabsTrigger className="w-full" value="ideal">iDEAL</TabsTrigger>
                                <TabsTrigger className="w-full" value="creditcard">Credit Card</TabsTrigger>
                                <TabsTrigger className="w-full" value="bancontact">Bancontact</TabsTrigger>
                            </TabsList>

                            <form onSubmit={handleSubmit} className="space-y-4">
                                <TabsContent value="ideal">
                                    <div className="lg:flex lg:gap-8">
                                        <div className="lg:flex-1">
                                            <div className="text-lost-description mb-6">
                                                <p className="text-lg">
                                                    Vul de gegevens in van je iDEAL betaling. Deze kun je terugvinden in je bankafschrift of bankapp.
                                                    Zorg dat je jouw IBAN nummer en de exacte omschrijving invult.
                                                </p>
                                            </div>
                                            <div className="mb-4">
                                                <Label>IBAN</Label>
                                                <Input
                                                    type="text"
                                                    value={formData.iban}
                                                    onChange={(e) => setFormData({ ...formData, iban: e.target.value })}
                                                    placeholder="NL81PSTB0000012345"
                                                    required
                                                />
                                                {getFieldError('iban') && (
                                                    <p className="mt-1 text-sm text-red-600">{getFieldError('iban')}</p>
                                                )}
                                            </div>
                                            <div>
                                                <Label>Omschrijving</Label>
                                                <Input
                                                    type="text"
                                                    value={formData.description}
                                                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                                    placeholder="Betaling voor..."
                                                    required
                                                />
                                                {getFieldError('description') && (
                                                    <p className="mt-1 text-sm text-red-600">{getFieldError('description')}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </TabsContent>

                                <TabsContent value="creditcard">
                                    <div className="lg:flex lg:gap-8">
                                        <div className="lg:flex-1">
                                            <div className="text-lost-description mb-6">
                                                <p className="text-lg">
                                                    Vul de gegevens in van je creditcard betaling. Deze kun je terugvinden op je creditcard afschrift.
                                                    Voor je veiligheid vragen we alleen om de laatste 4 cijfers van je kaartnummer.
                                                </p>
                                            </div>
                                            <div className="mb-4">
                                                <Label>Laatste 4 cijfers van je kaartnummer</Label>
                                                <div className="flex items-center gap-2">
                                                    <InputOTP maxLength={4} value={formData.lastFourDigits}
                                                        onChange={(value) => {
                                                            console.log(value);
                                                            setFormData({ ...formData, lastFourDigits: value });
                                                        }}>
                                                        <InputOTPGroup>
                                                            <InputOTPSlot index={0} />
                                                            <InputOTPSlot index={1} />
                                                            <InputOTPSlot index={2} />
                                                            <InputOTPSlot index={3} />
                                                        </InputOTPGroup>
                                                    </InputOTP>
                                                </div>
                                                {(getFieldError('issuerBin') || getFieldError('lastFourDigits')) && (
                                                    <p className="mt-1 text-sm text-red-600">
                                                        {getFieldError('issuerBin') || getFieldError('lastFourDigits')}
                                                    </p>
                                                )}
                                            </div>
                                            <div className="mb-4">
                                                <Label>Datum van de betaling</Label>
                                                <Input
                                                    type="date"
                                                    value={formData.date}
                                                    onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                                                    required
                                                />
                                                {getFieldError('date') && (
                                                    <p className="mt-1 text-sm text-red-600">{getFieldError('date')}</p>
                                                )}
                                            </div>
                                            <div className="mb-4">
                                                <Label>Bedrag</Label>
                                                <MoneyInput
                                                    value={formData.amount}
                                                    onChange={(amount) => setFormData({ ...formData, amount })}
                                                    required
                                                />
                                                {getFieldError('amountInCents') && (
                                                    <p className="mt-1 text-sm text-red-600">{getFieldError('amountInCents')}</p>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </TabsContent>

                                <TabsContent value="bancontact">
                                    <div className="text-lost-description mb-6">
                                        <p className="text-lg">
                                            Vul de gegevens in van je Bancontact betaling. Deze kun je terugvinden op je Bancontact afschrift.
                                            Zorg dat je jouw IBAN nummer, datum van de betaling en het exacte bedrag invult.
                                        </p>
                                    </div>
                                    <div className="mb-4">
                                        <Label>IBAN</Label>
                                        <Input
                                            type="text"
                                            value={formData.iban}
                                            onChange={(e) => setFormData({ ...formData, iban: e.target.value })}
                                            placeholder="NL81PSTB0000012345"
                                            required
                                        />
                                        {getFieldError('iban') && (
                                            <p className="mt-1 text-sm text-red-600">{getFieldError('iban')}</p>
                                        )}
                                    </div>
                                    <div className="mb-4">
                                        <Label>Payment Date</Label>
                                        <Input
                                            type="date"
                                            value={formData.date}
                                            onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                                            required
                                        />
                                        {getFieldError('date') && (
                                            <p className="mt-1 text-sm text-red-600">{getFieldError('date')}</p>
                                        )}
                                    </div>
                                    <div>
                                        <Label>Amount</Label>
                                        <MoneyInput
                                            value={formData.amount}
                                            onChange={(amount) => setFormData({ ...formData, amount })}
                                            required
                                        />
                                        {getFieldError('amountInCents') && (
                                            <p className="mt-1 text-sm text-red-600">{getFieldError('amountInCents')}</p>
                                        )}
                                    </div>
                                </TabsContent>
                                <div className="">
                                    <Button type="submit" className="w-full" disabled={isSubmitting}>
                                        {isSubmitting ? (
                                            <>
                                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                                Searching...
                                            </>
                                        ) : (
                                            'Zoek mijn bestelling'
                                        )}
                                    </Button>
                                </div>
                            </form>
                        </div>
                        <div className="bg-[#EDEDED] p-8 rounded-lg shadow-md hidden lg:flex justify-center items-center max-w-lg">
                            {paymentMethod === 'ideal' && (
                                <img src={idealStatement} alt="iDEAL Receipt Example" className="rounded shadow-lg w-full" />
                            )}
                            {paymentMethod === 'creditcard' && (
                                <img src={cardLookup} alt="Credit Card Receipt Example" className="w-full" />
                            )}
                            {paymentMethod === 'bancontact' && (
                                <img src={idealStatement} alt="Bancontact Receipt Example" className="rounded shadow-lg w-full" />
                            )}
                        </div>
                    </div>

                    <div className="bg-[#EDEDED] p-6 mt-6 rounded-lg w-full lg:hidden justify-center items-center">
                        {paymentMethod === 'ideal' && (
                            <img src={idealStatement} alt="iDEAL Receipt Example" className="rounded shadow-lg w-full" />
                        )}
                        {paymentMethod === 'creditcard' && (
                            <img src={cardLookup} alt="Credit Card Receipt Example" className="w-full" />
                        )}
                        {paymentMethod === 'bancontact' && (
                            <img src={idealStatement} alt="Bancontact Receipt Example" className="rounded shadow-lg w-full" />
                        )}
                    </div>
                </Tabs>
            </div>

            {foundOrders && foundOrders.payments.length > 1 && (
                <div className="mt-8">
                    <h2 className="text-xl font-semibold mb-4">Found Orders</h2>
                    <div className="space-y-4">
                        {foundOrders.payments.map((payment, index) => {
                            const url = new URL(payment.orderUrl);
                            const pathParts = url.pathname.split('/');
                            const orderId = pathParts[pathParts.length - 1];
                            
                            return (
                                <div 
                                    key={index}
                                    className="p-4 border rounded hover:bg-gray-50 cursor-pointer"
                                    onClick={() => navigate({ to: '/order/$orderId', params: { orderId } })}
                                >
                                    <span className="text-blue-600">Order #{orderId}</span>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
} 