import React, { createContext, useContext } from 'react';
import { PreRegistrationEvent } from '@/lib/pre-registration-api';

interface EventContextType {
    eventId: string;
    event: PreRegistrationEvent;
}

const EventContext = createContext<EventContextType | undefined>(undefined);

interface PreRegistrationEventProviderProps {
    event: PreRegistrationEvent;
    children: React.ReactNode;
}

export function useEvent() {
    const context = useContext(EventContext);
    if (context === undefined) {
        throw new Error('useEvent must be used within an EventProvider');
    }
    return context;
}

export function PreRegistrationEventProvider({ event, children }: PreRegistrationEventProviderProps) {
    return (
        <EventContext.Provider value={{ eventId: event.eventId, event }}>
            {children}
        </EventContext.Provider>
    );
} 