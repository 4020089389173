import { createFileRoute } from '@tanstack/react-router'
import { PreRegistrationContainer } from '@/PreRegistrationContainer'

export const Route = createFileRoute('/pre-registration/$slug')({
  component: PreRegistration,
})

function PreRegistration() {
  const { slug } = Route.useParams()
  return <PreRegistrationContainer shopSlug={slug} />
}
