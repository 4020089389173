'use client';
import { displayFee } from "@/lib/displayFee";
import { shopVar } from "@/lib/shopStyles";
import type {Product} from "@/lib/TicketApi";
import { Money } from "@/lib/money";
import ProductAvailability from "./ProductAvailability";
import { useLanguage } from '@/providers/LanguageProvider';

interface ProductSectionProps {
    product: Product;
}



export function Product({product}: ProductSectionProps) {
    const { currentLanguage: lang } = useLanguage();

    return (
        <div 
            className="border rounded-md px-4 pt-4 pb-2 mb-4 shadow-md last:mb-0" 
            style={{
                borderColor: shopVar('--shop-borderColor'),
            }}>
            
                <div className="flex items-center border-b border-dashed w-full pb-4" style={{
                    borderColor: shopVar('--shop-borderColor'),
                }}>
                    <div className="pr-4">
                        <h2 className="text-base font-bold" style={{
                            color: shopVar('--shop-textColor')
                        }}>{product.name}</h2>
                         <p className="font-normal text-xs tracking-tight" style={{
                            color: shopVar('--shop-textColor'),
                        }}>{Money.fromInteger(product.price.amount, product.price.currency).display(lang)} {displayFee(product.serviceFee, lang, '+ ', ' ' + product.serviceFee?.name)}</p>
                    </div>
                </div>
                <div className="pt-2">
                    <ProductAvailability product={product}/>
                </div>
            {/* <ProductErrorMessage message={productError(product.productId)}/> */}
        </div>
    );
}
