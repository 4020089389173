import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

type EventWithDates = {
    startDate: string;
    endDate: string;
};

export function displayEventDates(event: EventWithDates, locale: string): string {
    const startDate = new Date(event.startDate);
    const endDate = new Date(event.endDate);

    const dayFormatter = new Intl.DateTimeFormat(locale, { day: 'numeric' });
    const monthFormatter = new Intl.DateTimeFormat(locale, { month: 'long' });
    const fullDateFormatter = new Intl.DateTimeFormat(locale, { day: 'numeric', month: 'long' });
    const timeFormatter = new Intl.DateTimeFormat(locale, { hour: '2-digit', minute: '2-digit' });

    if (startDate.getFullYear() === endDate.getFullYear() &&
        startDate.getMonth() === endDate.getMonth() &&
        (startDate.getDate() === endDate.getDate() || (startDate.getDate() === endDate.getDate() - 1 && endDate.getHours() < 12))) {
            // Same day
            return `${dayFormatter.format(startDate)} ${monthFormatter.format(startDate)} ${timeFormatter.format(startDate)} - ${timeFormatter.format(endDate)}`;
    } else if (startDate.getFullYear() === endDate.getFullYear() &&
        startDate.getMonth() === endDate.getMonth()) {
            // Same month
            return `${dayFormatter.format(startDate)}-${dayFormatter.format(endDate)} ${monthFormatter.format(startDate)}`;
    } else {
            // Different months
            return `${fullDateFormatter.format(startDate)} - ${fullDateFormatter.format(endDate)}`;
    }
}
