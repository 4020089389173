import { createFileRoute, Outlet } from '@tanstack/react-router';
import { ShopProvider } from '@/providers/ShopProvider';
import { CartProvider } from '@/providers/CartProvider';
import { QueueContextProvider } from '@/providers/QueueContext';
import { TrackingProviderComponent } from '@/tracking/context';
import { LanguageProvider } from '@/providers/LanguageProvider';

export const Route = createFileRoute('/$slug/_layout')({
  component: ShopLayout,
});

function ShopLayout() {
  const { slug } = Route.useParams();
  
  return (
    <TrackingProviderComponent initialConfigs={[
      {type: "GOOGLE_ANALYTICS", id: "G-27QZ6PZXLM"},
    ]}>
      <LanguageProvider>
        <ShopProvider shopSlug={slug} baseUrl={import.meta.env.VITE_API_URL} isStandalone={true} usePageBackground={true}>
          <QueueContextProvider isQueueActive={false}>
            <CartProvider>
              <Outlet />
            </CartProvider>
          </QueueContextProvider>
        </ShopProvider>
      </LanguageProvider>
    </TrackingProviderComponent>
  );
} 