import { FormPartComponent, Select as SelectType } from "./FormParts";
import { useEffect, useState } from "react";
import { shopVar } from "@/lib/shopStyles";

export function Select({ formPart, onValueChange, onValidStatusChange, answers, errors }: FormPartComponent<SelectType>) {
    if (answers && typeof answers !== 'string') {
        answers = '';
    }

    const [value, setValue] = useState<string>(answers);

    // Initial validation
    useEffect(() => {
        onValidStatusChange(formPart.key, validateSelect(value || ''));
    }, [formPart.required]);

    const validateSelect = (selectedValue: string) => {
        if (!formPart.required) {
            return true;
        }
        return selectedValue.trim().length > 0;
    };

    const handleSelectChange = (newValue: string) => {
        setValue(newValue);
        onValueChange(formPart.key, newValue);
        onValidStatusChange(formPart.key, validateSelect(newValue));
    };

    return (
        <div className="grid grid-cols-1 gap-4 pt-4">
            <label className="block text-sm font-medium leading-6" style={{ color: shopVar('--shop-textColor') }}>
                {formPart.label}
                {/* {formPart.required && <span className="text-red-500 ml-1">*</span>} */}
            </label>
            <select
                value={value}
                onChange={(e) => handleSelectChange(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                style={{
                    color: shopVar('--shop-textColor'),
                    backgroundColor: shopVar('--shop-panelBackgroundColor'),
                    // @ts-expect-error --tw-ring-color is not a valid CSS property
                    '--tw-ring-color': shopVar('--shop-borderColor'),
                }}
            >
                {formPart.options.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
            {formPart.description && (
                <p className="text-sm text-gray-500">{formPart.description}</p>
            )}
            {errors.length > 0 && (
                <p className="mt-2 text-sm text-red-600">{errors[0]}</p>
            )}
        </div>
    );
} 