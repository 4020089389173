import React, { createContext, useContext, useState, useCallback } from 'react';
import { TrackingProvider } from './tracking-provider';
import { PixelConfig } from './adapters/BaseTrackingAdapter';
import { TrackingEvent } from './events';
import { GoogleAnalyticsConfig } from './adapters/GoogleAnalyticsAdapter';

interface TrackingContextValue {
  trackEvent: (event: TrackingEvent) => Promise<void>;
  addPixelConfig: (config: PixelConfig) => Promise<void>;
  resetPixelConfigs: () => Promise<void>;
}

const TrackingContext = createContext<TrackingContextValue | null>(null);

interface TrackingProviderProps {
  children: React.ReactNode;
  onError?: (error: Error) => void;
  initialConfigs?: (PixelConfig|GoogleAnalyticsConfig)[];
}

export const TrackingProviderComponent: React.FC<TrackingProviderProps> = ({
  children,
  onError,
  initialConfigs = [],
}) => {
  const [provider] = useState(
    () => new TrackingProvider(initialConfigs, onError)
  );

  const addPixelConfig = useCallback(async (config: PixelConfig|GoogleAnalyticsConfig) => {
    await provider.addPixelConfig(config);
  }, [provider]);

  const resetPixelConfigs = useCallback(async () => {
    // Get shopId from URL path
    const pathParts = window.location.pathname.split('/');
    const shopSlug = pathParts[1] || 'unknown';
    await provider.resetPixelConfigs(shopSlug);
  }, [provider]);

  const trackEvent = useCallback((event: TrackingEvent) => provider.trackEvent(event), [provider]);

  const contextValue = React.useMemo(() => ({
    trackEvent,
    addPixelConfig,
    resetPixelConfigs,
  }), [trackEvent, addPixelConfig, resetPixelConfigs]);

  return (
    <TrackingContext.Provider value={contextValue}>
      {children}
    </TrackingContext.Provider>
  );
};

export const useTracking = () => {
  const context = useContext(TrackingContext);
  if (!context) {
    throw new Error('useTracking must be used within a TrackingProvider');
  }
  return context;
};

// todo, implement the provider and call event to see if it works.