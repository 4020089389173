export enum FormPartType {
    OrderDetail = "orderDetails",
    Input = "input",
    Terms = "terms",
    Select = "select",
}

export type FormPartComponent<FP extends SupportedFormParts> = {
    formPart: FP,
    onValueChange: (formPartType: string, value: string|object) => void,
    onValidStatusChange: (formPartType: string, value: boolean) => void,
    answers: object[]|string[]|string,
    errors: string[]|string[][]
};

export type SupportedFormParts = OrderDetail | Input | Terms | Select;

export type FormPart<T extends FormPartType, TS extends object = object> = {
    type: T;
    key: string;
} & TS;

export type OrderDetail = FormPart<FormPartType.OrderDetail, {
    label: string,
    email: {
        label: string,
    },
    first_name: {
        label: string,
    },
    last_name: {
        label: string,
    },
    hasTerms: boolean,
    terms: {
        termsAndConditionsUrl: string,
        companyName: string,
    } | undefined
}>;

export type Terms = FormPart<FormPartType.Terms, {
    terms: {
        termsAndConditionsUrl: string,
        companyName: string,
    }
}>;

export type Input = FormPart<FormPartType.Input, {
    label: string,
    inputType: "text" | "date",
    description: string | null,
    placeholder: string | null,
    required: boolean,
}>;

export type Select = FormPart<FormPartType.Select, {
    label: string,
    description: string | null,
    placeholder: string | null,
    required: boolean,
    options: string[],
}>;
