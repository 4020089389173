import { createFileRoute } from '@tanstack/react-router';
import { EventIndex } from '@/components/event-index/EventIndex';

export const Route = createFileRoute('/$slug/_layout/')({
  component: ShopIndex,
});

function ShopIndex() {
  const { slug } = Route.useParams();
  return <EventIndex shopSlug={slug} />;
} 