// File: src/hooks/usePageTracking.ts
import { useEffect, useRef } from 'react';
import { useRouter, ParsedLocation } from '@tanstack/react-router';
import { useTracking } from '../tracking/context';
import { useShopWhenAvailable } from '../providers/ShopProvider';

export const usePageTracking = () => {
  const router = useRouter();
  const { trackEvent } = useTracking();
  const shopContext = useShopWhenAvailable();
  const initialPathRef = useRef(window.location.pathname);

  useEffect(() => {
    return router.subscribe('onResolved', () => {
      // Only track if the path has changed from the initial load
      if (router.state.location.pathname !== initialPathRef.current) {
        trackPageView(router.state.location);
      }
    });
  }, [router, trackEvent]);

  const getShopIdFromUrl = (): string => {
    // First try to get from context
    if (shopContext?.shopSlug) {
      return shopContext.shopSlug;
    }

    // Fallback to URL parsing
    const pathParts = window.location.pathname.split('/');
    // Assuming the shop slug is the first part of the path after the initial slash
    return pathParts[1] || 'unknown';
  };

  const trackPageView = (location: ParsedLocation) => {
    const route = location.pathname;
    const params = location.search;

    trackEvent({
      type: 'PAGE_VIEW',
      payload: {
        shopId: getShopIdFromUrl(),
        path: route,
        title: document.title,
        referrer: document.referrer,
        params: params
      }
    });
  };
};