import { useState, useEffect } from 'react';
import { ulid } from 'ulidx';

const QUEUE_API_URL = import.meta.env.VITE_QUEUE_API_URL;

interface QueueResponse {
  data: {
    allowed_access: boolean;
    access_expires_at: string | null;
    queue_status: 'countdown' | 'active';
    starts_at: number;
    message: string | null;
    queue_url?: string;
  };
}

function generateGuestUlid(): string {
  return ulid();
}

function useQueue(queueKey: string | undefined) {
  const [queueStatus, setQueueStatus] = useState<QueueResponse | null>(null);
  const [guestUlid] = useState(() => {
    // Retrieve or generate guest ULID
    const storedUlid = localStorage.getItem('guestUlid');
    if (storedUlid) return storedUlid;
    const newUlid = generateGuestUlid();
    localStorage.setItem('guestUlid', newUlid);
    return newUlid;
  });

  useEffect(() => {
    if (!queueKey || !QUEUE_API_URL) return;

    const checkQueueStatus = async () => {
      try {
        const response = await fetch(`${QUEUE_API_URL}/api/queue/${queueKey}/guest/${guestUlid}`);
        const data = await response.json();
        setQueueStatus(data);

        // If we're close to the start time or the queue is active, poll more frequently
        if (data.data.queue_status === 'active' || 
            (data.data.queue_status === 'countdown' && 
             data.data.starts_at - Date.now() / 1000 <= 3600)) {
          return 5000; // Poll every 5 seconds
        }
        return 30000; // Poll every 30 seconds
      } catch (error) {
        console.error('Failed to fetch queue status:', error);
        return 30000; // On error, keep default polling interval
      }
    };

    let timeoutId: number;
    const poll = async () => {
      const interval = await checkQueueStatus();
      timeoutId = window.setTimeout(poll, interval);
    };

    poll();

    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [queueKey, guestUlid]);

  return { queueStatus, guestUlid };
}

export default useQueue; 
