import React, { useState } from 'react';
import { DialogClose, DialogContent, DialogTitle } from '../shared/Dialog';
import { OrderApi, Ticket, TicketStatus, ResaleProperties } from '@/lib/OrderApi';
import { ImportInfo } from './ImportInfo';
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';
import { Money } from '@/lib/money';
import { useLanguage } from '@/providers/LanguageProvider';
import { useIsMobile } from '@/hooks/useIsMobile';
import { RiCashLine, RiCloseLine, RiShare2Line } from '@remixicon/react';
import { shareOnMobile } from 'react-mobile-share';
import copy from 'copy-to-clipboard';
import { useEvent } from '@/providers/EventProvider';
import * as m from "@/paraglide/messages.js"
import { displayFee } from '@/lib/displayFee';
import { calculateFee } from '@/lib/calculateFee';
import { MainButton } from '@/components/shared/MainButton';

function formatProfitSplitRatio(percentage: number): string {
  return `${100 - percentage}/${percentage}`;
}

function hasResaleProperties(ticket: Ticket): ticket is Ticket & { canResell: true } & ResaleProperties {
    return 'canResell' in ticket && ticket.canResell === true;
}

interface ListTicketModalProps {
    marketplaceUrl: string|null,
    orderId: string,
    ticket: Ticket,
    onSuccess: () => void,
}

const ListTicketModal: React.FC<ListTicketModalProps> = ({orderId, ticket, onSuccess, marketplaceUrl}) => {

  const { currentLanguage: lang } = useLanguage();
  
  const [loading, setLoading] = useState(false);

  const [inMemoryTicket, setTicket] = useState<Ticket>(ticket);

  // const [price, setPrice] = useState<number>((ticket.price.amount + ticket.serviceFee.amount) / 100);
  const [price, setPrice] = useState<string>(((ticket.price.amount + ticket.serviceFee.amount) / 100).toString());
  const [priceError, setPriceError] = useState<string | undefined>(undefined);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  
  const [showCopied, setShowCopied] = useState(false);

  const { event } = useEvent();
  const isMobile = useIsMobile();

  const shareUrl = () => {
    if(inMemoryTicket.ticketStatus !== TicketStatus.forSale) {
      return;
    }

    return `${marketplaceUrl}?listing=${inMemoryTicket.listingId}`;
  }

  const copyOrShareUrl = () => {
    if(inMemoryTicket.ticketStatus !== TicketStatus.forSale) {
      return;
    }

    const url = shareUrl();

    if(url === undefined) {
      return;
    }
    
    if (isMobile) {
      shareOnMobile({
        text: `I'm selling my ticket for ${event?.name}`,
        url,
        title: `I'm selling my ticket for ${event?.name}`,
        images: [],
      });
    } else {
      copy(url, {
        message: "Share link copied to clipboard",
      });
      setShowCopied(true);
      setTimeout(() => {
        setShowCopied(false);
      }, 3000);
    }
  };

  async function sellTicket(): Promise<void> {
    setLoading(true);
    try {
      const ticketApi = new OrderApi(import.meta.env.VITE_API_URL);
      const listingId = await ticketApi.listTicket(orderId, ticket.ticketId, Money.fromInteger(Number(price) * 100, 'eur'));
      
      if (!hasResaleProperties(ticket)) {
        return;
      }

      const listingPrice = Money.fromInteger(Number(price) * 100, 'eur');
      const serviceFee = calculateFee(ticket.resaleFeeSeller, listingPrice, 1);
      const originalTotal = Money.fromInteger(ticket.price.amount + ticket.serviceFee.amount, 'eur');
      const profit = listingPrice.subtract(originalTotal);
      const profitSplit = ticket.profitSplitPercentageForOrganizer > 0 && profit.amount > 0
        ? profit.multiply(ticket.profitSplitPercentageForOrganizer / 100)
        : Money.zero('eur');
      
      setTicket({
        ...ticket,
        ticketStatus: TicketStatus.forSale,
        listingPriceExFee: {
          amount: Number(price) * 100,
          currency: 'eur',
        },
        listingId: listingId,
        sellerReceivable: {
          amount: listingPrice.subtract(serviceFee).subtract(profitSplit).amount,
          currency: 'eur'
        },
        organizationReceivableAmount: {
          amount: profitSplit.amount,
          currency: 'eur'
        },
        resaleFeeAmountSeller: {
          amount: serviceFee.amount,
          currency: 'eur'
        },
        sellerFee: ticket.resaleFeeSeller,
        maxResellPercentage: ticket.maxResellPercentage,
        profitSplitPercentageForOrganizer: ticket.profitSplitPercentageForOrganizer,
        canResell: false,
        onMarketplace: true,
      });
      onSuccess();
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  let limit = 0;

  if((ticket.ticketStatus === TicketStatus.available || ticket.ticketStatus === TicketStatus.claimable) && ticket.canResell) {
    limit = ticket.maxResellPrice.amount;
  }

  const handleOnValueChange: CurrencyInputProps['onValueChange'] = (_value) => {
    setPriceError(undefined);
    if (!_value) {
      setPrice('');
      return;
    }

    // value is over limit
    if (Number(_value) > (limit/100)) {
      setPrice((limit/100).toString());
      setPriceError(`Can't be more than ${Money.fromInteger(limit, 'eur').display(lang)}`);
      return;
    }

    if(Number(_value) <= 0) {
      setPrice('0');
      setPriceError(`Can't be less than 0`);
      return;
    }

    setPrice(_value);
  };

  const unlistTicket = async () => {
    setLoading(true);
    setError(undefined);
    const ticketApi = new OrderApi(import.meta.env.VITE_API_URL);
    const result = await ticketApi.unlistTicket(orderId, ticket.ticketId);
    if(result === true) {
      onSuccess();
      window.location.reload();
    } else {
      setError("Cant unlist ticket at this moment: " + result);
    }
    setLoading(false);
  }

  return (
      <DialogContent className='bg-[var(--shop-panelBackgroundColor)] text-[var(--shop-textColor)]'>
        <DialogTitle className='text-center mx-auto'>{ticket.productName} <span className='text-xs tracking-tight'> {ticket.numberOfProductType}/{ticket.totalOfProductType}</span></DialogTitle>
        {inMemoryTicket.ticketStatus === TicketStatus.forSale && (
          <div>
            <p className="text-xl font-bold mt-6">
              Your ticket is listed for {Money.fromInteger(inMemoryTicket.listingPriceExFee.amount, inMemoryTicket.listingPriceExFee.currency).display(lang)}
            </p>

            <ImportInfo className='pt-4 pb-2'>
                <ImportInfo.Item>Once sold you will get an email, the funds will be deposited witin 5 business days.</ImportInfo.Item>
                <ImportInfo.Item>If you change your mind you can always cancel the listing, but once sold it is irreversible</ImportInfo.Item>
                {inMemoryTicket.organizationReceivableAmount.amount > 0 && (
                  <ImportInfo.Item>Your profit is split {formatProfitSplitRatio(inMemoryTicket.profitSplitPercentageForOrganizer)} with the organiser</ImportInfo.Item>
                )}
                <ImportInfo.Item>Find and share your listing using the URL: <a href={shareUrl()} target="_blank" className='underline text-[var(--shop-ctaButtonColor)]'>{shareUrl()}</a></ImportInfo.Item>
            </ImportInfo>

            <div className="mt-6 space-y-2 p-4 rounded-md text-right">
              <div className="flex justify-end text-sm">
                <span>Service fee ({displayFee(inMemoryTicket.sellerFee, lang)}):</span>
                <span className='pl-2'>-{Money.fromInteger(inMemoryTicket.resaleFeeAmountSeller.amount, inMemoryTicket.resaleFeeAmountSeller.currency).display(lang)}</span>
              </div>
              {inMemoryTicket.organizationReceivableAmount.amount > 0 && (
                <div className="flex justify-end text-sm">
                  <span>Profit split ({formatProfitSplitRatio(inMemoryTicket.profitSplitPercentageForOrganizer)}):</span>
                  <span className='pl-2'>-{Money.fromInteger(inMemoryTicket.organizationReceivableAmount.amount, inMemoryTicket.organizationReceivableAmount.currency).display(lang)}</span>
                </div>
              )}
              <div className="flex justify-end font-semibold border-t border-[var(--shop-borderColor)] pt-2 text-lg">
                <span>Payout after sale:</span>
                <span className='pl-2'>{Money.fromInteger(inMemoryTicket.sellerReceivable.amount, inMemoryTicket.sellerReceivable.currency).display(lang)}</span>
              </div>
            </div>

              <div className='flex flex-col items-end'>
                <MainButton onClick={copyOrShareUrl} className="w-full">
                  <div className="flex items-center justify-center gap-2">
                    {isMobile ? "Share ticket" : "Copy share link to clipboard"} <RiShare2Line className='w-6 h-6' />
                  </div>
                </MainButton>
                {showCopied && <div className='pt-1 text-xs opacity-70'>Share link copied to clipboard</div>}
              </div>

              <MainButton 
                className="mt-4 w-full"
                disabled={loading}
                loading={loading}
                onClick={() => unlistTicket()}
                style={{
                  backgroundColor: 'var(--shop-removeFromCartButtonColor)',
                  color: 'var(--shop-removeFromCartButtonTextColor)',
                }}
              >
                <div className="flex items-center justify-center gap-2">
                  {loading ? "Unlisting..." : "Cancel listing"}
                  <RiCloseLine className='w-6 h-6' />
                </div>
              </MainButton>

              {error && <p className="mt-2 text-sm text-[var(--shop-productErrorMessageText)] bg-[var(--shop-productErrorMessageBackground)] p-2 rounded">{error}</p>}

              <DialogClose asChild>
                <button className='bg-[var(--shop-ctaButtonColor)] text-[var(--shop-ctaButtonTextColor)] p-2 rounded-md text-center block w-full hover:opacity-90 transition-all duration-300 hover:shadow-md mt-4'>
                    {m.close()}
                </button>
            </DialogClose>
          </div>
        )}
        {(inMemoryTicket.ticketStatus === TicketStatus.claimable || inMemoryTicket.ticketStatus === TicketStatus.available) && inMemoryTicket.canResell && (
          <div className="">
            <p className="text-xl font-bold mt-6">
              List your ticket
            </p>

            <ImportInfo className='pt-4 pb-2'>
                <ImportInfo.Item>You can list your ticket on the marketplace</ImportInfo.Item>
                <ImportInfo.Item>Once sold you will get an email, the funds will be deposited witin 5 business days</ImportInfo.Item>
                <ImportInfo.Item>You can cancel the listing at any time, but once sold it is irreversible</ImportInfo.Item>
                {hasResaleProperties(ticket) && ticket.profitSplitPercentageForOrganizer > 0 && (
                  <ImportInfo.Item>Your profit is split {formatProfitSplitRatio(ticket.profitSplitPercentageForOrganizer)} with the organiser</ImportInfo.Item>
                )}
            </ImportInfo>

            <div className='flex items-center gap-2 py-2'>
              <div className='grow'>
                <CurrencyInput
                  id="price"
                  name="price"
                  placeholder="Price"
                  value={price}
                  decimalsLimit={2}
                  onValueChange={handleOnValueChange}
                  prefix="€"
                  className="mt-4 block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-[var(--shop-borderColor)] placeholder:text-[var(--shop-textColor)] placeholder:opacity-40 focus:ring-2 focus:ring-inset focus:ring-[var(--shop-ctaButtonColor)] sm:text-sm sm:leading-6 bg-[var(--shop-panelBackgroundColor)] text-[var(--shop-textColor)]"
                />
              </div>
              <div className='text-xs tracking-tight'>
                Max: {Money.fromInteger(limit, 'eur').display(lang)}
              </div>
            </div>
            {priceError && <p id="price-error" className="mt-2 text-sm text-[var(--shop-productErrorMessageText)] bg-[var(--shop-productErrorMessageBackground)] p-2 rounded">{priceError}</p>}
            {!priceError && <p className="mt-2 text-sm opacity-70">This price can not be changed while listed</p>}
            {!priceError && hasResaleProperties(ticket) && (
              <div className="mt-6 space-y-2 p-4 rounded-md text-right">
                <div className="flex justify-end text-sm">
                  <span>Service fee ({displayFee(ticket.resaleFeeSeller, lang)}):</span>
                  <span className='pl-2'>{calculateFee(
                    ticket.resaleFeeSeller,
                    Money.fromInteger(Number(price || 0) * 100, 'eur'),
                    1
                  ).display(lang)}</span>
                </div>
                {hasResaleProperties(ticket) && ticket.profitSplitPercentageForOrganizer > 0 && (Number(price || 0) * 100) > (ticket.price.amount + ticket.serviceFee.amount) && (
                  <div className="flex justify-end text-sm">
                    <span>Profit split ({formatProfitSplitRatio(ticket.profitSplitPercentageForOrganizer)}):</span>
                    <span className='pl-2'>{Money.fromInteger(
                      ((Number(price || 0) * 100) - (ticket.price.amount + ticket.serviceFee.amount)) * (ticket.profitSplitPercentageForOrganizer / 100),
                      'eur'
                    ).display(lang)}</span>
                  </div>
                )}
                <div className="flex justify-end font-semibold border-t border-[var(--shop-borderColor)] pt-2 text-lg">
                  <span>Payout after sale:</span>
                  <span className='pl-2'>{(() => {
                    const totalPrice = Money.fromInteger(Number(price || 0) * 100, 'eur');
                    const serviceFee = calculateFee(ticket.resaleFeeSeller, totalPrice, 1);
                    const originalTotal = Money.fromInteger(ticket.price.amount + ticket.serviceFee.amount, 'eur');
                    
                    const profit = totalPrice.subtract(originalTotal);

                    const profitSplit = ticket.profitSplitPercentageForOrganizer > 0 && profit.amount > 0
                      ? profit.multiply(ticket.profitSplitPercentageForOrganizer / 100)
                      : Money.zero('eur');
                    
                    return totalPrice.subtract(serviceFee).subtract(profitSplit).display(lang);
                  })()}</span>
                </div>
              </div>
            )}
          
            <div className="relative flex items-start mt-4">
              <div className="flex h-6 items-center">
                <input
                  id="terms"
                  name="terms"
                  type="checkbox"
                  aria-describedby="terms-description"
                  className="h-4 w-4 rounded border-[var(--shop-borderColor)] focus:ring-[var(--shop-ctaButtonColor)]"
                  checked={agreeToTerms}
                  onChange={() => setAgreeToTerms(!agreeToTerms)}
                />
              </div>
              <div className="ml-3 text-sm leading-6">
                <label htmlFor="comments" className="font-medium">
                    {m.agree_to_terms_pt1()}<a href="https://www.fastlane.events/fastlane-terms-conditions/fastlane-terms-and-conditions-for-ticketing-services" className="font-bold text-[var(--shop-ctaButtonColor)]" target="_blank">Fastlane ticketing</a>
                </label>
              </div>
            </div>

            <MainButton 
              className="mt-4 w-full"
              disabled={loading || !agreeToTerms || priceError !== undefined}
              loading={loading}
              onClick={() => sellTicket()}
            >
              <div className="flex items-center justify-center gap-2">
                {loading ? "Listing ticket..." : "List ticket"}
                <RiCashLine className='w-6 h-6' />
              </div>
            </MainButton>
          </div>
        )}
      </DialogContent>
  );
};

export default ListTicketModal;
