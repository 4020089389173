import { Money } from "@/lib/money";
import {ServiceFee} from "@/lib/TicketApi";

export function calculateFee(serviceFee: ServiceFee, totalAmount: Money, quantity: number) : Money {
    if(serviceFee.amountPerUnit === undefined && serviceFee.percentage === undefined) {
        return Money.zero(totalAmount.currency);
    }

    if(serviceFee.amountPerUnit === null && serviceFee.percentage === null) {
        return Money.zero(totalAmount.currency);
    }

    // Initialize fee with zero
    let fee = Money.zero(totalAmount.currency);

    // Add unit-based fee if it exists
    if(serviceFee.amountPerUnit !== undefined && serviceFee.amountPerUnit !== null) {
        if(serviceFee.amountPerUnit.amount > 0) {
            fee = fee.add(Money.fromInteger(serviceFee.amountPerUnit.amount, serviceFee.amountPerUnit.currency).multiply(quantity));
        }
    }

    // Add percentage-based fee if it exists
    if(serviceFee.percentage !== undefined && serviceFee.percentage !== null && serviceFee.percentage > 0) {
        fee = fee.add(totalAmount.multiply(serviceFee.percentage / 100));
    }

    return fee;
}
