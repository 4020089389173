import { createFileRoute } from '@tanstack/react-router'

import { Panel } from '@/components/shared/Panel';
import { MainButton } from '@/components/shared/MainButton';
import { RiArrowLeftSLine, RiArrowRightSLine } from '@remixicon/react';
import { displayFee } from '@/lib/displayFee';
import { useEffect, useState } from 'react';
import { CustomerDetailsForm } from '@/components/customer-details-form/CustomerDetailsForm';

import { useMarketplaceCart } from '@/providers/MarketplaceCartProvider';
import { Money } from '@/lib/money';
import { useLanguage } from '@/providers/LanguageProvider';
import QuantitySelectButton from '@/components/QuantitySelectButton';
import { clsx } from 'clsx';
import { shopVar } from '@/lib/shopStyles';
import OrderPageShell from '@/components/order/OrderPageShell';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/shared/Accordion';
import { CartItems } from '@/components/marketplace/CartItems';
import { WaitlistSignup } from '@/components/marketplace/WaitlistSignup';
import { CartTimeoutWarning } from '@/components/marketplace/CartTimeoutWarning';
import { StyleOverwrites } from '@/providers/StyleOverwrites';

export const Route = createFileRoute('/marketplace/$slug/')({
  validateSearch: (search: Record<string, unknown>): {listing: string|undefined} => {
    return {
      listing: search.listing as string|undefined
    }
  },
  component: MarketplaceContent,
})

function MarketplaceContent() {

  const { currentLanguage: lang } = useLanguage();
  const { cart, updateQuantity, isLoading, cartTotal, getCartItemTotal, marketplace, formState, setFormState, formValid, setFormValid, refreshCart } = useMarketplaceCart();
  const [step, setStep] = useState<'cart' | 'form'>("cart");

  const { listing: filteredListingId } = Route.useSearch();

  useEffect(() => {
    if(cart.orderId !== null) {
      refreshCart();
    }
  }, [cart.orderId]);


  const navigate = Route.useNavigate();

  return (<StyleOverwrites shopStyle={marketplace.style}>
    <OrderPageShell event={marketplace.event} shopStyle={marketplace.style}>
      {step === 'cart' && (
            <>
                <div className='bg-white px-8 pt-8 pb-4 -mt-4 rounded-b-md' style={{
                    backgroundColor: shopVar('--shop-panelBackgroundColor'),
                    color: shopVar('--shop-textColor')
                }}>
                    <div>
                        <div className='text-lg font-bold'>Resale marketplace</div>
                        <div className='text-xs pt-2'>
                            {marketplace.forSaleCount} for sale • {marketplace.soldCount} sold • {marketplace.waitlistCount} wanted on waitlist
                        </div>
                        <div className='text-xs'>
                            Buying a ticket here 100% guarantees entrance
                        </div>
                    </div>
                    <WaitlistSignup slug={marketplace.slug} />
                    <div>
                {Object.entries(marketplace.listings.filter(l => filteredListingId === undefined || l.id === filteredListingId).reduce((acc, listing) => {
                    if (!acc[listing.product_id]) {
                        acc[listing.product_id] = [];
                    }
                    acc[listing.product_id].push(listing);
                    return acc;
                }, {} as Record<string, typeof marketplace.listings>))
                .map(([productId, listings]) => (
                    <Accordion 
                        key={productId}
                        className={clsx('py-2rounded-md justify-between items-center')}
                        style={{backgroundColor: shopVar('--shop-panelBackgroundColor')}}
                        type="single"
                        defaultValue={filteredListingId ? 'opened' : 'closed'}
                        collapsible
                            >
                                <AccordionItem value="opened">
                                    <AccordionTrigger canCollapse={true}>
                                        <div className="flex w-full flex-col sm:flex-row items-start sm:items-center justify-between">
                                            <div className="text-xl font-bold leading-normal">{listings[0].product_name}</div>
                                            <div className='sm:ml-4 text-sm'>{listings.flatMap(l => l.available).reduce((a, b) => a + b, 0)} tickets, lowest price {Money.fromInteger(Math.min(...listings.flatMap(l => l.price.amount)), listings[0].price.currency).display(lang)}</div>
                                        </div>
                                    </AccordionTrigger>
                                    <AccordionContent>
                                        {listings
                                        .sort((a, b) => a.price.amount - b.price.amount)
                                        .map(listing => (
                                            <div key={listing.id} className='bg-gray-50 p-4 mb-4 last:mb-0 rounded-md shadow-md flex justify-between items-center gap-2' style={{
                                                backgroundColor: shopVar('--shop-panelBackgroundColor'),
                                                color: shopVar('--shop-textColor'),
                                                borderColor: shopVar('--shop-borderColor')
                                            }}>
                                                <div className='flex items-center gap-2'>
                                                    <svg width="33" height="35" viewBox="0 0 33 35" className="mt-1 w-8 h-8 mx-2 hidden sm:block" style={{ fill: shopVar('--shop-textColor') }} xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M21.6112 16.0254C20.1723 16.9868 18.4806 17.5 16.75 17.5C14.4294 17.5 12.2038 16.5781 10.5628 14.9372C8.92187 13.2962 8 11.0706 8 8.75C8 7.01941 8.51318 5.32769 9.47464 3.88876C10.4361 2.44983 11.8027 1.32832 13.4015 0.666058C15.0004 0.00379123 16.7597 -0.169488 18.457 0.168133C20.1544 0.505753 21.7135 1.33911 22.9372 2.56282C24.1609 3.78653 24.9942 5.34563 25.3319 7.04296C25.6695 8.74029 25.4962 10.4996 24.8339 12.0985C24.1717 13.6973 23.0502 15.0639 21.6112 16.0254ZM0.5 30C0.5 23.35 11.3266 20 16.75 20C22.1734 20 33 23.35 33 30V35H0.5V30ZM20.7222 24.8469C20.7222 24.6518 20.6546 24.4647 20.5342 24.3268C20.4746 24.2584 20.4038 24.2042 20.3259 24.1672C20.248 24.1302 20.1645 24.1111 20.0801 24.1111C19.9958 24.1111 19.9123 24.1302 19.8343 24.1672C19.7564 24.2042 19.6856 24.2584 19.626 24.3268L15.2376 29.3559L13.2705 27.1018C13.2113 27.0315 13.1404 26.9754 13.0621 26.9369C12.9838 26.8983 12.8995 26.878 12.8143 26.8772C12.729 26.8763 12.6445 26.8949 12.5656 26.9319C12.4867 26.9689 12.415 27.0236 12.3547 27.0926C12.2944 27.1617 12.2468 27.2438 12.2145 27.3343C12.1822 27.4247 12.1659 27.5215 12.1667 27.6192C12.1674 27.7169 12.1851 27.8135 12.2188 27.9032C12.2524 27.993 12.3013 28.0741 12.3627 28.142L14.7535 30.8812C14.8171 30.9541 14.8926 31.0119 14.9757 31.0514C15.0588 31.0908 15.1479 31.1111 15.2378 31.1111C15.3278 31.1111 15.4168 31.0908 15.4999 31.0514C15.583 31.0119 15.6585 30.9541 15.7221 30.8812L20.5342 25.367C20.6546 25.229 20.7222 25.042 20.7222 24.8469Z"/>
                                                    </svg>
                                                    <div>
                                                        <div className='font-semibold mt-1'>{Math.max(listing.available, cart.reserved.find(item => item.listingId === listing.id)?.quantity || 0)} tickets</div>
                                                        <div className='text-xs tracking-tight mt-1'>Verified seller</div>
                                                    </div>
                                                    <div className='text-left sm:pl-2 md:pl-10'>
                                                        <div className='text-xl font-bold'>{Money.fromInteger(listing.price.amount, listing.price.currency).display(lang)}</div>
                                                        <div className='text-xs tracking-tight'>{displayFee(listing.fee, lang, '+ ', " " + listing.fee.name)}</div>
                                                    </div>
                                                </div>
                                                
                                                <QuantitySelectButton
                                                    quantity={cart.reserved.find(item => item.listingId === listing.id)?.quantity || 0}
                                                    limit={listing.available}
                                                    loading={isLoading[listing.id] || false}
                                                    onIncrease={() => updateQuantity(listing.id, (cart.reserved.find(item => item.listingId === listing.id)?.quantity || 0) + 1)}
                                                    onDecrease={() => updateQuantity(listing.id, (cart.reserved.find(item => item.listingId === listing.id)?.quantity || 0) - 1)}
                                                />
                                            </div>
                                        ))}
                                    </AccordionContent>
                                </AccordionItem>
                            </Accordion>
                ))}
            </div>
                </div>
                <Panel>
                    <CartItems reserved={cart.reserved} listings={marketplace.listings} getCartItemTotal={getCartItemTotal}/>
                    <div className='flex justify-end'>
                        <div className='text-xl font-bold'>Total: </div>
                        <div className='text-xl font-bold leading-7 ml-2'>{cartTotal.display(lang)}</div>
                    </div>
                    <div className='py-2'>
                        <MainButton disabled={(cart.reserved.flatMap(item => item.quantity).reduce((a, b) => a + b, 0) === 0) || Object.keys(isLoading).flatMap(key => isLoading[key]).reduce((a, b) => a || b, false)} onClick={() => {setStep('form')}}>
                            <div className='text-xl font-bold leading-7'>Next</div>
                            <RiArrowRightSLine className="h-8 w-8 shrink-0 group-hover:translate-x-2 group-hover:scale-110 transition-transform duration-200"/>
                        </MainButton>
                        {cart.timesOutAtUtc && (
                            <CartTimeoutWarning timesOutAtUtc={cart.timesOutAtUtc} slug={marketplace.slug} />
                        )}
                    </div>
                </Panel>
            </>
        )}
        {step === 'form' && (
            <Panel>
                <CustomerDetailsForm
                        form={marketplace.form}
                        answers={formState as Record<string, string[] | object[]>}
                        errors={{}}
                        setAnswers={setFormState}
                        setFormValid={setFormValid}
                    />
                    <div className='flex gap-2 mt-4'>
                        <div className='border-2 border-black rounded-md p-2 hover:cursor-pointer hover:opacity-80 hover:bg-gray-100' style={{
                            borderColor: shopVar('--shop-textColor'),
                            color: shopVar('--shop-textColor')
                        }} onClick={async () => {
                            setStep('cart');
                        }}>
                            <RiArrowLeftSLine className="h-10 w-10 shrink-0 group-hover:translate-x-2 group-hover:scale-110 transition-transform duration-200"/>
                        </div>
                        <MainButton disabled={!formValid} 
                            className='flex-grow'
                            onClick={async () => {
                                navigate({ to: '/marketplace/$slug/checkout/$cart', params: { cart: cart.cartId } });
                            }}>
                            <div className='text-lg font-bold leading-7'>Next</div>
                            <RiArrowRightSLine className="h-10 w-10 shrink-0 group-hover:translate-x-2 group-hover:scale-110 transition-transform duration-200"/>
                        </MainButton>
                    </div>
                    {cart.timesOutAtUtc && (
                        <CartTimeoutWarning timesOutAtUtc={cart.timesOutAtUtc} slug={marketplace.slug} />
                        )}
            </Panel>
        )}
    </OrderPageShell>
  </StyleOverwrites>);
}

