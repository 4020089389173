import { useEffect, useState } from 'react';
import { createFileRoute } from '@tanstack/react-router';
import { useLanguage } from '@/providers/LanguageProvider';
import { useMarketplaceCart } from '@/providers/MarketplaceCartProvider';
import { CartItems } from '@/components/marketplace/CartItems';
import { SelectPaymentMethod } from '@/components/purchase/checkout/SelectPaymentMethod';
import { Panel } from '@/components/shared/Panel';
import { MainButton } from '@/components/shared/MainButton';
import { RiArrowLeftSLine, RiArrowRightSLine } from '@remixicon/react';
import { shopVar } from '@/lib/shopStyles';
import { Money } from '@/lib/money';
import { CartTimeoutDialog } from '@/components/shared/Dialog';
import { PaymentMethod } from '@/lib/TicketApi';
import { calculateFee } from '@/lib/calculateFee';
import OrderPageShell from '@/components/order/OrderPageShell';
import { CartTimeoutWarning } from '@/components/marketplace/CartTimeoutWarning';
import { StyleOverwrites } from '@/providers/StyleOverwrites';

export const Route = createFileRoute('/marketplace/$slug/checkout/$cart')({
  component: Checkout
});

interface CheckoutResponse {
  type: "redirect" | "error";
  url?: string;
  error?: string;
}

function Checkout() {
  const cartId = Route.useParams().cart;
  const navigate = Route.useNavigate();
  const { currentLanguage: lang } = useLanguage();
  const {checkoutLoading, cartTotal, startCheckout, marketplace, cart, getCartItemTotal, setCartIdFromRouteSlug} = useMarketplaceCart();
  const [showTimeoutDialog, setShowTimeoutDialog] = useState(false);

  useEffect(() => {
    setCartIdFromRouteSlug(cartId);
  }, [cartId]);

  // useEffect(() => {
  //   if (cart.answers) {
  //     setFormState(cart.answers);
  //   }
  // }, [cart.answers]);

  const [paymentMethod, setPaymentMethod] = useState<string|null>(marketplace.paymentMethods[0].id);

  const paymentMethodFee = (): Money|null => {
    if(!paymentMethod) return null;
    const selectedPaymentMethod = marketplace.paymentMethods.find(pm => pm.id === paymentMethod);
    if(!selectedPaymentMethod) return null;

    return calculateFee(selectedPaymentMethod.fee, cartTotal, 1);
  }

  const selectedPaymentMethod = (): PaymentMethod|null => {
      if(!paymentMethod) return null;
      const selectedPaymentMethod = marketplace.paymentMethods.find(pm => pm.id === paymentMethod);
      if(!selectedPaymentMethod) return null;
      return selectedPaymentMethod;
  }

  const handleCheckout = async () => {
    if(!paymentMethod || cart.pendingPayment) return;
    try {
      const result = await startCheckout(paymentMethod) as CheckoutResponse;
      if (result.type === 'error' && result.error === 'Cart has timed out') {
        setShowTimeoutDialog(true);
      }
    } catch (error) {
      console.error('Checkout failed:', error);
    }
  };

  const handleTimeoutDialogClose = () => {
    setShowTimeoutDialog(false);
    navigate({ 
      to: '/marketplace/$slug', 
      params: { slug: marketplace.slug },
      search: { listing: undefined }
    });
  };

  return <StyleOverwrites shopStyle={marketplace.style}>
    <OrderPageShell event={marketplace.event} shopStyle={marketplace.style}>
      <Panel>
        <CartTimeoutDialog isOpen={showTimeoutDialog} onClose={handleTimeoutDialogClose} />
        {cart.pendingPayment && (
          <div>
            <div>Payment pending...</div>
          </div>
        )}

        {cart.orderId && (
          <div>
            <div>Payment successful!</div>
          </div>
        )}

        {!cart.pendingPayment && !cart.orderId && (
          <SelectPaymentMethod 
              className='py-6'
              paymentMethods={marketplace.paymentMethods}
              selectedMethod={paymentMethod}
              onMethodChange={setPaymentMethod}
              totalAmount={cartTotal}
          />
        )}
        <CartItems reserved={cart.reserved} listings={marketplace.listings} getCartItemTotal={getCartItemTotal} />
        <div className='flex flex-col items-end gap-2 mt-2 pt-2 border-t border-dashed' style={{
              borderColor: shopVar('--shop-borderColor'),
              color: shopVar('--shop-textColor')
          }}>
              {paymentMethodFee() && !paymentMethodFee()!.isZero() && (
                  <>
                      <div className='text-sm tracking-tight'>Subtotaal: {cartTotal.display(lang)}</div>
                      <div className='text-sm tracking-tight'>{selectedPaymentMethod()?.fee.name}: {paymentMethodFee()!.display(lang)}</div>
                  </>
              )}
              <div className='font-bold text-2xl'>{cartTotal.add(paymentMethodFee() || Money.zero(cartTotal.currency)).display(lang)}</div>
          </div>
          {cart.orderId && (
            <MainButton 
              className='flex-grow mt-4'
              onClick={() => {
                  navigate({ to: '/order/$orderId', params: { orderId: cart.orderId! }});
              }}>
              <div className='text-lg font-bold leading-7'>Collect your order</div>
              <RiArrowRightSLine className="h-10 w-10 shrink-0 group-hover:translate-x-2 group-hover:scale-110 transition-transform duration-200"/>
            </MainButton>
          )}

          {!cart.orderId && (
            <>
            <div className='flex gap-2 mt-4'>
              {!cart.pendingPayment && (
                <div className='border-2 border-black rounded-md p-2 hover:cursor-pointer hover:opacity-80 hover:bg-gray-100' style={{
                    borderColor: shopVar('--shop-textColor'),
                    color: shopVar('--shop-textColor')
                }} onClick={async () => {
                  navigate({ to: '/marketplace/$slug', params: { slug: marketplace.slug }, search: { listing: undefined }});
                }}>
                    <RiArrowLeftSLine className="h-10 w-10 shrink-0 group-hover:translate-x-2 group-hover:scale-110 transition-transform duration-200"/>
                </div>
              )}
              <MainButton disabled={!paymentMethod || checkoutLoading || cart.pendingPayment} 
                  loading={checkoutLoading}
                  className='flex-grow'
                  onClick={handleCheckout}>
                  <div className='text-lg font-bold leading-7'>Checkout</div>
                  <RiArrowRightSLine className="h-10 w-10 shrink-0 group-hover:translate-x-2 group-hover:scale-110 transition-transform duration-200"/>
              </MainButton>
            </div>
             {cart.timesOutAtUtc && (
              <CartTimeoutWarning timesOutAtUtc={cart.timesOutAtUtc} slug={marketplace.slug} />
            )}  
            </>
          )}
      </Panel>
    </OrderPageShell>
  </StyleOverwrites>;
}

export default Checkout;
