import { displayEventDates } from "@/lib/utils";
import { LanguageSelect } from "../LanguageSelect";
import { shopVar } from "@/lib/shopStyles";
import { useEvent as useShopEvent } from "@/providers/EventProvider";
import { useEvent as usePreRegistrationEvent } from "@/providers/PreRegistrationEventProvider";
import { useShopWhenAvailable } from "@/providers/ShopProvider";
import { RiCloseLine } from "@remixicon/react";

export const Header: React.FC = () => {
    let event;
    
    try {
        // Try to use pre-registration event context first
        const preRegContext = usePreRegistrationEvent();
        event = preRegContext.event;
    } catch {
        try {
            // Fall back to shop event context
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const shopContext = useShopEvent(); 
            event = shopContext.event;
        } catch {
            // If neither context is available, event will remain undefined
        }
    }
    
    const { 
        isWidget, 
        shopSlug, 
        shop, 
    } = useShopWhenAvailable() ?? { 
        isWidget: false, 
        shopSlug: '', 
        shop: null,
    };

    const handleClose = () => {
        window.dispatchEvent(new CustomEvent('shop-close', { detail: { shopSlug } }));
    };

    const showLocation = !shop?.style?.hideLocation && event?.location?.name;
    const showDate = !shop?.style?.hideDate;

    return (
        <header>
            <div className={`relative p-4 bg-cover bg-center bg-no-repeat h-28 w-full ${isWidget ? 'md:rounded-md md:rounded-b-none' : 'md:rounded-3xl md:rounded-b-none'}`} 
            style={{backgroundImage: `url(${event?.headerImage || shop?.events?.[0]?.headerImage})`}}>
                {isWidget && (
                    <div 
                        className='absolute top-6 right-6 p-2 hover:cursor-pointer hover:bg-gray-100/10 rounded-full hover:scale-105 transition-transform duration-200' 
                        onClick={handleClose}
                    >
                        <RiCloseLine size={30} color={'black'}/>
                    </div>
                )}
            </div>
            <div className="px-8 py-4 flex items-center rounded-md rounded-t-none justify-between" style={{backgroundColor: shopVar('--shop-panelBackgroundColor')}}>
                <div>
                    <div className='text-xl font-bold'>{event?.name || 'Events'}</div>
                    {event && (
                        <div>
                            {showLocation && showDate && `${event.location.name} | ${displayEventDates(event, 'nl')}`}
                            {showLocation && !showDate && event.location.name}
                            {!showLocation && showDate && displayEventDates(event, 'nl')}
                        </div>
                    )}
                </div>
                <LanguageSelect/>
            </div>
        </header>
    );
};