import { format, fromUnixTime } from 'date-fns';
import { CountdownTimer } from './CountdownTimer';
import { shopVar } from '@/lib/shopStyles';
import {  RiLoopRightFill, RiNotification2Fill } from '@remixicon/react';

import * as m from "@/paraglide/messages.js"

interface QueueOverlayProps {
  queueStatus: {
    data: {
      queue_status: 'countdown' | 'active';
      starts_at: number;
      message: string | null;
      allowed_access: boolean;
    };
  };
  onNotificationsEnabled: () => void;
  notificationsEnabled: boolean;
}

export function QueueOverlay({ queueStatus, onNotificationsEnabled, notificationsEnabled }: QueueOverlayProps) {
  if (!queueStatus?.data) return null;

  const { queue_status, starts_at, message } = queueStatus.data;
  const now = Math.floor(Date.now() / 1000);
  const isWithinOneHour = starts_at - now <= 3600;
  const hasStarted = now >= starts_at;

  return (
    <div className="p-8">  
      {queue_status === 'countdown' && !hasStarted && (
        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold" style={{ color: shopVar('--shop-textColor') }}>
            {m.queue_not_started()}
          </h2>
          <p className="text-gray-600 py-4">
            {m.queue_not_started_description()}
          </p>
          {isWithinOneHour ? (
            <>
              <p className="text-gray-600 mb-4">{m.queue_starts_in()}</p>
              <CountdownTimer targetTimestamp={starts_at} />
            </>
          ) : (
            <>
              <p className="text-gray-600 mb-2">{m.queue_starts_at()}</p>
              <p className="text-xl font-semibold">
                {format(fromUnixTime(starts_at), 'MMMM d, yyyy HH:mm')}
              </p>
            </>
          )}
        </div>
      )}

      {(queue_status === 'active' || (queue_status === 'countdown' && hasStarted)) && (
        <>
        <div className="text-center mb-8">
          <h2 className="text-2xl font-bold mb-4" style={{ color: shopVar('--shop-textColor') }}>
              {m.queue_your_in_line()}
            </h2>
          <p className="text-gray-600">
            {m.queue_please_wait()}
          </p>
          <div className='flex items-center justify-center mt-4 mx-auto'>
            <p className="text-sm text-gray-500">
              {m.queue_refresh_instruction()}
            </p>
            <div className="[animation:spin_2s_linear_infinite] h-4 w-4 ml-4">
                  <RiLoopRightFill className="h-4 w-4" />
              </div>
          </div> 
        </div>
        {!notificationsEnabled && (
          <div className="text-center mt-8 pt-8 border-t border-gray-200">
            <button
              onClick={onNotificationsEnabled}
              className="inline-flex items-center px-4 py-2 rounded-md text-sm font-medium text-white bg-[var(--shop-ctaButtonColor)] hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[var(--shop-ctaButtonColor)]"
            >
              <RiNotification2Fill className="mr-2 h-5 w-5" />
              {m.queue_notify_cta()}
            </button>
            <p className="mt-2 text-sm text-gray-500">
              {m.queue_notify_instruction()}
            </p>
          </div>
        )}
        </>

      )}



      {message && (
        <div className="mt-6 p-4 bg-white text-black rounded-lg text-xl font-bold flex items-center border border-red-500 shadow-lg">
          <RiNotification2Fill className="mr-2 h-5 w-5" />
          {message}
        </div>
      )}
    </div>
  );
} 