import { createFileRoute } from '@tanstack/react-router';
import { EventContainer } from '@/EventContainer';
import { useCart } from '@/providers/CartProvider';
import { useEffect, useRef } from 'react';

export const Route = createFileRoute('/$slug/_layout/$event')({
  component: Event,
});

function Event() {
  const { event } = Route.useParams();
  const { applyCoupon } = useCart();
  const couponApplied = useRef(false);
  
  useEffect(() => {
    const applyQueryCouponCode = async () => {
      if (couponApplied.current) return;
      
      const searchParams = new URLSearchParams(window.location.search);
      const couponCode = searchParams.get('couponcode');
      
      if (couponCode) {
        // Add a delay to prevent race condition with cart initialization
        setTimeout(async () => {
          try {
            await applyCoupon(couponCode);
            couponApplied.current = true;
          } catch (error) {
            console.error('Failed to apply coupon code:', error);
          }
        }, 200);
      }
    };
    
    applyQueryCouponCode();
  }, [applyCoupon]);
  
  return <EventContainer eventSlug={event} />;
} 
