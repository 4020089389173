import React from 'react';
import { Money } from '@/lib/money';

interface MoneyInputProps {
    value: Money;
    onChange: (value: Money) => void;
    required?: boolean;
    className?: string;
}

export const MoneyInput: React.FC<MoneyInputProps> = ({ value, onChange, required, className }) => {
    const euros = Math.floor(value.amount / 100);
    const cents = value.amount % 100;

    const handleEurosChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newEuros = e.target.value.replace(/\D/g, '');
        const newAmount = (parseInt(newEuros || '0', 10) * 100) + cents;
        onChange(Money.fromInteger(newAmount, 'EUR'));
    };

    const handleCentsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newCents = e.target.value.replace(/\D/g, '').slice(0, 2);
        const newAmount = (euros * 100) + parseInt(newCents || '0', 10);
        onChange(Money.fromInteger(newAmount, 'EUR'));
    };

    return (
        <div className={`flex items-center gap-1 ${className || ''}`}>
            <span className="text-[var(--shop-textColor)]">€</span>
            <input
                type="number"
                min="0"
                max="999999"
                step="1"
                value={euros}
                onChange={handleEurosChange}
                className="-mr-2 w-20 rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-[var(--shop-borderColor)] placeholder:text-[var(--shop-textColor)] placeholder:opacity-40 focus:ring-2 focus:ring-inset focus:ring-[var(--shop-ctaButtonColor)] sm:text-sm sm:leading-6 bg-[var(--shop-panelBackgroundColor)] text-[var(--shop-textColor)] text-right [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                required={required}
                placeholder="0"
            />
            <span className="text-[var(--shop-textColor)]">,</span>
            <input
                type="number"
                min="0"
                max="99"
                step="1"
                value={cents}
                onChange={handleCentsChange}
                className="w-12 -ml-2 rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-[var(--shop-borderColor)] placeholder:text-[var(--shop-textColor)] placeholder:opacity-40 focus:ring-2 focus:ring-inset focus:ring-[var(--shop-ctaButtonColor)] sm:text-sm sm:leading-6 bg-[var(--shop-panelBackgroundColor)] text-[var(--shop-textColor)] [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                required={required}
                placeholder="00"
            />
        </div>
    );
}; 