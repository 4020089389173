/**
 * Utility function to handle redirects in iFrame environments
 * This handles special considerations for mobile browsers that have
 * additional security restrictions with iFrames
 */
export function handleRedirect(url: string): void {
  if (window !== window.parent) {
    // We're in an iframe, use special handling
    
    // First try window.open which works in most browsers
    const newWindow = window.open(url, '_blank');
    
    // If window.open was blocked or returns null, try alternative approaches
    if (!newWindow) {
      setTimeout(() => {
        try {
          // Try to navigate the top-level browsing context (may fail due to CORS)
          // @ts-expect-error - window.top is not defined in the current context
          window.top.location.href = url;
        } catch (e) {
          // As a last resort, create and click a link
          const link = document.createElement('a');
          link.href = url;
          link.target = '_blank';
          link.rel = 'noopener noreferrer';
          
          // Make it visible on mobile to encourage user interaction
          // This can help bypass restrictions on some mobile browsers
          link.style.position = 'fixed';
          link.style.top = '50%';
          link.style.left = '50%';
          link.style.transform = 'translate(-50%, -50%)';
          link.style.padding = '20px';
          link.style.background = '#4a90e2';
          link.style.color = 'white';
          link.style.borderRadius = '8px';
          link.style.boxShadow = '0 4px 8px rgba(0,0,0,0.2)';
          link.style.textDecoration = 'none';
          link.style.zIndex = '9999';
          link.style.display = 'flex';
          link.style.justifyContent = 'center';
          link.style.alignItems = 'center';
          link.textContent = 'Continue to Payment';
          
          document.body.appendChild(link);
          
          // Try to auto-click, but the visible button serves as fallback
          try {
            link.click();
          } catch(err) {
            console.log('Auto-click failed, using visible button as fallback');
            // Button is already visible for the user to click
          }
          
          // Remove after a delay if the user hasn't clicked it
          setTimeout(() => {
            if (document.body.contains(link)) {
              document.body.removeChild(link);
            }
          }, 10000);
        }
      }, 100);
    }
  } else {
    // We're not in an iframe, use normal navigation
    window.location.href = url;
  }
} 