import React from 'react';
import * as Select from '@radix-ui/react-select';
import { RiArrowDownSLine } from '@remixicon/react';
import { useLanguage } from '@/providers/LanguageProvider';
import { useShopStyle } from '@/providers/StyleOverwrites';

const languages = [
    { value: 'en', label: '🇬🇧 English' },
    { value: 'nl', label: '🇳🇱 Nederlands' },
];

export const LanguageSelect: React.FC = () => {
    const { currentLanguage, changeLanguage } = useLanguage();
    const shopStyle = useShopStyle();

    return (
        <div className="relative">
            <Select.Root
                value={currentLanguage}
                onValueChange={changeLanguage}
            >
                <Select.Trigger
                    className="opacity-80 inline-flex items-center justify-center focus:shadow-[0_0_0_1px] outline-none"
                    aria-label="Language"
                    style={{backgroundColor: shopStyle.panelBackgroundColor}}
                >
                    <Select.Value placeholder="Select a language.." />
                    <Select.Icon className="">
                        <RiArrowDownSLine />
                    </Select.Icon>
                </Select.Trigger>

                <Select.Portal>
                    <Select.Content
                        className="overflow-hidden rounded-md shadow-[0_2px_10px] shadow-black/[.1]"
                        position="popper"
                        style={{backgroundColor: shopStyle.panelBackgroundColor}}
                        sideOffset={5}
                    >
                        <Select.Viewport className="p-1">
                            {languages.map((language) => (
                                <Select.Item
                                    key={language.value}
                                    value={language.value}
                                    className="leading-none pointer-events-auto hover:cursor-pointer rounded-[3px] flex items-center h-[25px] pr-[35px] pl-[25px] relative select-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:outline-none data-[highlighted]:bg-gray-100 py-4"
                                    style={{backgroundColor: shopStyle.panelBackgroundColor, color: shopStyle.textColor}}
                                >
                                    <Select.ItemText>{language.label}</Select.ItemText>
                                </Select.Item>
                            ))}
                        </Select.Viewport>
                    </Select.Content>
                </Select.Portal>
            </Select.Root>
        </div>
    );
};
