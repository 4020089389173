import { FormPartComponent, Input as InputType } from "./FormParts";
import { useEffect, useState } from "react";
import { shopVar } from "@/lib/shopStyles";

export function Input({ formPart, onValueChange, onValidStatusChange, answers, errors }: FormPartComponent<InputType>) {

    if(answers && typeof answers !== 'string') {
        answers = ''
    }

    const [value, setValue] = useState<string>(answers);
    
    // Initialize date parts from the value if it exists
    const [day, setDay] = useState<string>('');
    const [month, setMonth] = useState<string>('');
    const [year, setYear] = useState<string>('');

    // Initial validation for all fields
    useEffect(() => {
        if (formPart.inputType === 'date') {
            const isValid = !formPart.required || (day.length > 0 && month.length > 0 && year.length > 0);
            onValidStatusChange(formPart.key, isValid);
        } else {
            onValidStatusChange(formPart.key, validateTextField(value || ''));
        }
    }, [formPart.required]);

    // Initialize date values from answers
    useEffect(() => {
        if (formPart.inputType === 'date') {
            const dateValue = answers as string;
            if (dateValue) {
                const [y, m, d] = dateValue.split('-');
                setYear(y ?? '');
                setMonth(m ?? '');
                setDay(d ?? '');
                setValue(dateValue);
            } else {
                setYear('');
                setMonth('');
                setDay('');
                setValue('');
            }
            // Mark as invalid if required and any part is missing
            const dateParts = dateValue ? dateValue.split('-') : [];
            const hasAllParts = dateParts.length === 3 && dateParts.every(part => part.length > 0);
            const isValid = !formPart.required || hasAllParts;
            onValidStatusChange(formPart.key, isValid);
        }
    }, [answers, formPart.key, formPart.required]);

    const validateDateFields = (newDay: string, newMonth: string, newYear: string) => {
        if (!formPart.required) {
            // If not required, it's valid if either all fields are empty or all are filled
            const allEmpty = !newDay && !newMonth && !newYear;
            const allFilled = newDay !== '' && newMonth !== '' && newYear !== '';
            return allEmpty || allFilled;
        }
        // If required, all fields must be filled with non-empty values
        return newDay !== '' && newMonth !== '' && newYear !== '';
    };

    const validateTextField = (text: string) => {
        if (!formPart.required) {
            return true;
        }
        return text.trim().length > 0;
    };

    const handleDateChange = (type: 'day' | 'month' | 'year', newValue: string) => {
        let newDay = day;
        let newMonth = month;
        let newYear = year;

        if (type === 'day') newDay = newValue;
        if (type === 'month') newMonth = newValue;
        if (type === 'year') newYear = newValue;

        // Update the individual field state
        if (type === 'day') setDay(newValue);
        if (type === 'month') setMonth(newValue);
        if (type === 'year') setYear(newValue);

        // Validate and update
        const isValid = validateDateFields(newDay, newMonth, newYear);
        onValidStatusChange(formPart.key, isValid);
        
        // Only update the combined value when all fields are filled
        if (newDay && newMonth && newYear) {
            const dateString = `${newYear}-${newMonth.padStart(2, '0')}-${newDay.padStart(2, '0')}`;
            setValue(dateString);
            onValueChange(formPart.key, dateString);
        } else if (!formPart.required) {
            // If not required and fields are cleared, clear the value
            setValue('');
            onValueChange(formPart.key, '');
        }
    };

    const handleTextChange = (newValue: string) => {
        setValue(newValue);
        onValueChange(formPart.key, newValue);
        onValidStatusChange(formPart.key, validateTextField(newValue));
    };

    // Memoize these arrays to prevent recreating them on every render
    const days = Array.from({ length: 31 }, (_, i) => (i + 1).toString().padStart(2, '0'));
    const months = Array.from({ length: 12 }, (_, i) => ({
        value: (i + 1).toString().padStart(2, '0'),
        label: new Intl.DateTimeFormat(undefined, { month: 'long' }).format(new Date(2024, i))
    }));
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 100 }, (_, i) => (currentYear - i).toString());

    if (formPart.inputType === 'date') {
        return (
            <div className="grid grid-cols-1 gap-4 pt-4">
                <label className="block text-sm font-medium leading-6" style={{ color: shopVar('--shop-textColor') }}>
                    {formPart.label}
                    {/* {formPart.required && <span className="text-red-500 ml-1">*</span>} */}
                </label>
                <div className="grid grid-cols-3 gap-4">
                    <select
                        value={day}
                        onChange={(e) => handleDateChange('day', e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                        style={{
                            color: shopVar('--shop-textColor'),
                            backgroundColor: shopVar('--shop-panelBackgroundColor'),
                            // @ts-expect-error --tw-ring-color is not a valid CSS property
                            '--tw-ring-color': shopVar('--shop-borderColor'),
                        }}
                    >
                        <option value="">Day</option>
                        {days.map(d => (
                            <option key={d} value={d}>{d}</option>
                        ))}
                    </select>
                    <select
                        value={month}
                        onChange={(e) => handleDateChange('month', e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                        style={{
                            color: shopVar('--shop-textColor'),
                            backgroundColor: shopVar('--shop-panelBackgroundColor'),
                            // @ts-expect-error --tw-ring-color is not a valid CSS property
                            '--tw-ring-color': shopVar('--shop-borderColor'),
                        }}
                    >
                        <option value="">Month</option>
                        {months.map(m => (
                            <option key={m.value} value={m.value}>{m.label}</option>
                        ))}
                    </select>
                    <select
                        value={year}
                        onChange={(e) => handleDateChange('year', e.target.value)}
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6"
                        style={{
                            color: shopVar('--shop-textColor'),
                            backgroundColor: shopVar('--shop-panelBackgroundColor'),
                            // @ts-expect-error --tw-ring-color is not a valid CSS property
                            '--tw-ring-color': shopVar('--shop-borderColor'),
                        }}
                    >
                        <option value="">Year</option>
                        {years.map(y => (
                            <option key={y} value={y}>{y}</option>
                        ))}
                    </select>
                </div>
                {errors.length > 0 && <p className="mt-2 text-sm text-red-600">{errors[0]}</p>}
            </div>
        );
    }

    return (
        <div className="grid grid-cols-1 gap-4 pt-4">
            <label className="block text-sm font-medium leading-6" style={{ color: shopVar('--shop-textColor') }}>
                {formPart.label}
                {/* {formPart.required && <span className="text-red-500 ml-1">*</span>} */}
            </label>
            <input
                type="text"
                value={value}
                onChange={(e) => handleTextChange(e.target.value)}
                placeholder={formPart.placeholder ?? undefined}
                className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 placeholder:text-[color:var(--shop-textColor)] placeholder:opacity-60"
                style={{
                    color: shopVar('--shop-textColor'),
                    backgroundColor: shopVar('--shop-panelBackgroundColor'),
                    // @ts-expect-error --tw-ring-color is not a valid CSS property
                    '--tw-ring-color': shopVar('--shop-borderColor'),
                }}
            />
            {formPart.description && <p className="text-sm text-gray-500">{formPart.description}</p>}
            {errors.length > 0 && <p className="mt-2 text-sm text-red-600">{errors[0]}</p>}
        </div>
    );
} 