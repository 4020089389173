import { FormPartComponent, Terms as TermsType } from "./FormParts";
import { useEffect } from "react";
import { useForm } from "@mantine/form";
import { shopVar } from "@/lib/shopStyles";
import * as m from "@/paraglide/messages.js";

export function Terms({ formPart, onValueChange, onValidStatusChange, answers }: FormPartComponent<TermsType>) {
    const form = useForm({
        mode: 'controlled',
        initialValues: {
            termsOfService: typeof answers === 'string' ? answers === "true" : false,
        },
        validateInputOnChange: [
            'termsOfService'
        ],
        validate: {
            termsOfService: (value: boolean) => (value ? null : m.must_agree_to_tos()),
        },
        onValuesChange: (values) => {
            onValueChange(formPart.key, values.termsOfService ? "true" : "false");
            onValidStatusChange(formPart.key, form.isValid());
        }
    });

    useEffect(() => {
        onValidStatusChange(formPart.key, form.isValid());
    }, []);

    return (
        <div className="relative flex items-start pt-6">
            <div className="flex h-6 items-center">
                <input
                    id="terms"
                    name="terms"
                    type="checkbox"
                    aria-describedby="terms-description"
                    className="h-4 w-4 rounded border-gray-300 focus:ring-indigo-600"
                    style={{
                        color: '#222222',
                    }}
                    {...form.getInputProps('termsOfService', { type: 'checkbox' })}
                />
            </div>
            <div className="ml-3 text-sm leading-6">
                <label htmlFor="comments" className="font-medium" style={{
                    color: shopVar('--shop-textColor'),
                }}>
                    {m.agree_to_terms_pt1()}<a href="https://www.fastlane.events/fastlane-terms-conditions/fastlane-terms-and-conditions-for-ticketing-services" className="font-bold" target="_blank">Fastlane ticketing</a> {m.agree_to_terms_pt2()} <a href={formPart.terms.termsAndConditionsUrl} className="font-bold" target="_blank">{formPart.terms.companyName}</a>
                </label>
                {form.errors.termsOfService && <p id="termsOfService-error" className="text-sm text-red-600">{form.errors.termsOfService}</p>}
            </div>
        </div>
    );
} 