import TikTokPixelAdapter from "./adapters/TiktokPixelAdapater";
import GoogleAnalyticsAdapter, { GoogleAnalyticsConfig } from "./adapters/GoogleAnalyticsAdapter";
import MetaPixelAdapter from "./adapters/metaPixelAdapter";
import { TrackingAdapter } from "./adapters/BaseTrackingAdapter";
import { PixelConfig } from "./adapters/BaseTrackingAdapter";

import { TrackingEvent } from "./events";
import { FastlaneTrackingAdapter } from "./adapters/FastlaneTracking";

export class TrackingProvider {
  private adapters: Map<string, TrackingAdapter> = new Map();
  private eventBuffer: Array<TrackingEvent> = [];
  private initialized = false;
  private initialConfigs: (PixelConfig|GoogleAnalyticsConfig)[];
  private currentShopSlug: string | null = null;

  constructor(
    initialConfigs: (PixelConfig|GoogleAnalyticsConfig)[] = [],
    private onError?: (error: Error) => void
  ) {
    this.initialConfigs = [...initialConfigs];
    this.initializePixels(initialConfigs);
  }

  private async initializePixels(configs: (PixelConfig|GoogleAnalyticsConfig)[]) {
    for (const config of configs) {
      await this.addPixelConfig(config);
    }
    this.initialized = true;
  }

  private getPixelKey(config: PixelConfig): string {
    return `${config.type}-${config.id}`;
  }

  async addPixelConfig(config: PixelConfig|GoogleAnalyticsConfig): Promise<void> {
    const pixelKey = this.getPixelKey(config);
    
    // Check if this pixel is already initialized
    if (this.adapters.has(pixelKey)) {
      return;
    }

    try {
      let adapter: TrackingAdapter;
      
      switch (config.type) {
        case 'META':
          adapter = new MetaPixelAdapter(config);
          break;
        case 'GOOGLE_ANALYTICS':
          adapter = new GoogleAnalyticsAdapter(config as GoogleAnalyticsConfig);
          break;
        case 'TIKTOK':
          adapter = new TikTokPixelAdapter(config);
          break;
        case 'FASTLANE':
          adapter = FastlaneTrackingAdapter.fromConfig(config);
          break;
        default:
          throw new Error(`Unknown pixel type: ${config.type}`);
      }

      await adapter.initialize();
      this.adapters.set(pixelKey, adapter);
      
      // Send all buffered events to the new adapter
      for (const event of this.eventBuffer) {
        await adapter.trackEvent(event).catch(error => this.onError?.(error));
      }
    } catch (error) {
      this.onError?.(error as Error);
    }
  }

  async resetPixelConfigs(shopSlug: string | null): Promise<void> {
    console.log('resetPixelConfigs', shopSlug);
    // Only clear buffer if we're switching to a different shop
    if (this.currentShopSlug !== shopSlug) {
      this.eventBuffer = [];
      this.currentShopSlug = shopSlug;
    }
    
    // Clear existing adapters
    this.adapters.clear();
    
    // Reinitialize with initial configs
    await this.initializePixels(this.initialConfigs);
  }

  async trackEvent(event: TrackingEvent): Promise<void> {
    // Always add to buffer regardless of initialization status
    this.eventBuffer.push(event);

    // If initialized, also send to current adapters
    if (this.initialized) {
      await this.sendEvent(event);
    }
  }

  private async sendEvent(
    event: TrackingEvent
  ): Promise<void> {
    const promises = Array.from(this.adapters.values()).map(adapter =>
      adapter.trackEvent(event).catch(error => this.onError?.(error))
    );

    await Promise.all(promises);
  }
}