import {FunctionComponent, useEffect, useState} from "react";
import { FormPartComponent, FormPartType, SupportedFormParts } from "./FormParts/FormParts";
import { OrderDetails } from "./FormParts/OrderDetails";
import { Input } from "./FormParts/Input";
import { Terms } from "./FormParts/Terms";
import { Select } from "./FormParts/Select";
import { OrderedList } from "@/lib/TicketApi";
import { FormComponent } from "@/lib/TicketApi";

interface CustomerDetailsFormProps {
    form: OrderedList<FormComponent>;
    answers: Record<string, object[] | string[]>;
    errors: Record<string, string[]>;
    setAnswers: (answers: Record<string, object[] | string[] | string>) => void;
    setFormValid: (valid: boolean) => void;
}

export function CustomerDetailsForm({form, answers, errors, setAnswers, setFormValid}: CustomerDetailsFormProps) {
    const [formPartValidStatus, setFormPartValidStatus] = useState<Record<string, boolean>>({});

    const formPartComponents = {
        [FormPartType.OrderDetail]: OrderDetails,
        [FormPartType.Input]: Input,
        [FormPartType.Terms]: Terms,
        [FormPartType.Select]: Select,
    } as {[k in FormPartType]: FunctionComponent<FormPartComponent<SupportedFormParts>>};

    const allFormPartsAreValid = Object.keys(formPartValidStatus).every((key) => {
        return formPartValidStatus[key];
    });

    useEffect(() => {
        setFormValid(allFormPartsAreValid);
    }, [allFormPartsAreValid, setFormValid]);

    return (
        <>
            {Object.values(form)
                .filter((formPart: FormComponent) => formPart.type in formPartComponents)
                .map((formPart: FormComponent, index: number) => {
                const Component = formPartComponents[formPart.type as FormPartType];
                return (
                    <Component 
                        key={index}
                        formPart={formPart as SupportedFormParts}
                        answers={answers[formPart.key] ?? []}
                        errors={errors[formPart.key] ?? []}
                        onValidStatusChange={(formPartType: string, value: boolean) => setFormPartValidStatus({
                            ...formPartValidStatus,
                            [formPartType]: value
                        })}
                        onValueChange={(formPartType: string, value: string|object) => {
                            // @ts-expect-error todo, check how to correctly type this
                            setAnswers({
                                ...answers,
                                [formPartType]: value
                            });
                        }}
                    />
                )
            })}
        </>
    )
}
