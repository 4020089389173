/**
 * Converts a CSS color value to RGB components
 */
function getRGBComponents(color: string): [number, number, number] {
    // Handle hex colors
    if (color.startsWith('#')) {
        const hex = color.replace('#', '');
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);
        return [r, g, b];
    }

    // Handle rgb/rgba colors
    if (color.startsWith('rgb')) {
        const values = color.match(/\d+/g);
        if (values && values.length >= 3) {
            return [
                parseInt(values[0], 10),
                parseInt(values[1], 10),
                parseInt(values[2], 10)
            ];
        }
    }

    // For other formats or invalid colors, return white
    return [255, 255, 255];
}

/**
 * Calculates the relative luminance of a color
 * Returns true if the color is light, false if it's dark
 */
export function isLightColor(color: string): boolean {
    const [r, g, b] = getRGBComponents(color);
    
    // Calculate relative luminance using the formula from WCAG 2.0
    // https://www.w3.org/TR/WCAG20/#relativeluminancedef
    const [rs, gs, bs] = [r / 255, g / 255, b / 255].map(c => {
        if (c <= 0.03928) {
            return c / 12.92;
        }
        return Math.pow((c + 0.055) / 1.055, 2.4);
    });
    
    const luminance = 0.2126 * rs + 0.7152 * gs + 0.0722 * bs;
    
    // Return true for light colors (luminance > 0.5)
    return luminance > 0.5;
} 