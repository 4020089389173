import { TrackingEvent } from "../events";
import { PixelDetector } from "./BaseTrackingAdapter";

import BaseTrackingAdapter from "./BaseTrackingAdapter";

// Meta Pixel Adapter
export default class MetaPixelAdapter extends BaseTrackingAdapter {
    async initialize(): Promise<void> {
      if (this.initialized) return;
  
      if (PixelDetector.hasMetaPixel()) {
        // If fbq exists, just initialize our pixel ID
        (window as any).fbq('init', this.config.id);
        this.initialized = true;
        return;
      }
  
      // Load Meta Pixel script if it doesn't exist
      const script = document.createElement('script');
      script.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${this.config.id}');
      `;
      document.head.appendChild(script);
      this.initialized = true;
    }
  
    async trackEvent(event: TrackingEvent): Promise<void> {
      if (!this.initialized) throw new Error('Meta Pixel not initialized');
      
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const fbq = (window as any).fbq;
      
      switch (event.type) {
        case 'PAGE_VIEW':
          fbq('track', 'PageView');
          break;
  
        case 'VIEW_ITEM':
          fbq('track', 'ViewContent', {
            content_type: 'product',
            content_ids: [event.payload.productId],
            content_name: event.payload.name,
            value: event.payload.price,
            currency: event.payload.currency
          });
          break;
  
        case 'ADD_TO_CART':
          fbq('track', 'AddToCart', {
            content_type: 'product',
            content_ids: [event.payload.productId],
            content_name: event.payload.name,
            value: event.payload.price * event.payload.quantity,
            currency: event.payload.currency,
            quantity: event.payload.quantity
          }, {
            eventID: event.payload.eventId
          });
          break;
  
        case 'BEGIN_CHECKOUT':
          fbq('track', 'InitiateCheckout', {
            content_type: 'product',
            content_ids: event.payload.items.map(item => item.productId),
            value: event.payload.totalValue,
            currency: event.payload.currency,
            num_items: event.payload.items.reduce((sum, item) => sum + item.quantity, 0)
          });
          break;
  
        case 'PURCHASE':
          fbq('track', 'Purchase', {
            content_type: 'product',
            content_ids: event.payload.items.map(item => item.productId),
            value: event.payload.totalValue,
            currency: event.payload.currency,
            num_items: event.payload.items.reduce((sum, item) => sum + item.quantity, 0),
            transaction_id: event.payload.transactionId
          }, {
            eventID: event.payload.cartId
          });
          break;
  
        case 'CUSTOM':
          fbq('trackCustom', event.payload.eventName, event.payload);
          break;
      }
    }
  }