import React from 'react';
import { Ticket, TicketStatus, TicketType } from '@/lib/OrderApi';
import EntranceTicket from './ticket-types/EntranceTicket';
import TapConnectTicket from './ticket-types/TapConnectTicket';

interface AvailableTicketProps {
  ticket: Ticket;
}

const AvailableTicket: React.FC<AvailableTicketProps> = ({ ticket }) => {
  if (ticket.ticketStatus !== TicketStatus.available) {
    return null;
  }

  if (ticket.ticketType === TicketType.TapConnect) {
    return <TapConnectTicket ticket={ticket} />;
  }

  return <EntranceTicket ticket={ticket} />;
};

export default AvailableTicket;       