import { createContext, useContext, ReactNode, useState } from 'react';

interface QueueContextType {
  isQueueActive: boolean;
}

const QueueContext = createContext<QueueContextType>({ isQueueActive: false });

export function useQueueContext() {
  return useContext(QueueContext);
}

interface QueueContextProviderProps {
  children: ReactNode;
  isQueueActive: boolean;
}

let updateQueueState: (isActive: boolean) => void = () => {};

export function QueueContextProvider({ children, isQueueActive: initialQueueState }: QueueContextProviderProps) {
  const [isQueueActive, setIsQueueActive] = useState(initialQueueState);

  // Store the update function in the module-level variable
  updateQueueState = setIsQueueActive;

  return (
    <QueueContext.Provider value={{ isQueueActive }}>
      {children}
    </QueueContext.Provider>
  );
}

// Add static method to update queue state
QueueContextProvider.updateQueueState = (isActive: boolean) => {
  updateQueueState(isActive);
}; 
