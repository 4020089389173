import { CustomerDetailsForm } from '@/components/customer-details-form/CustomerDetailsForm';
import { ImportInfo } from '@/components/order/ImportInfo';
import OrderPageShell from '@/components/order/OrderPageShell';
import { SelectPaymentMethod } from '@/components/purchase/checkout/SelectPaymentMethod';
import { Money } from '@/lib/money';
import { OrderApi, PendingPersonalisationTicket as PendingPersonalisationTicketType} from '@/lib/OrderApi';
import { useLanguage } from '@/providers/LanguageProvider';
import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { useEffect, useState } from 'react';
import { StyleOverwrites } from '@/providers/StyleOverwrites';
import { shopVar } from '@/lib/shopStyles';
import { MainButton } from '@/components/shared/MainButton';
import { calculateFee } from '@/lib/calculateFee';
import { handleRedirect } from '@/lib/iframeRedirect';

type shareCodeSearch = {
    orderId: string|undefined;
}

export const Route = createFileRoute('/ticket-share/$shareCode')({
    validateSearch: (search: Record<string, unknown>): shareCodeSearch => {
        return {
            orderId: search.orderId as string|undefined
        }
    },
    loaderDeps: ({search: {orderId}}) => ({orderId}),
    loader: async ({ params, deps: {orderId} }) => {
        const ticketApi = new OrderApi(import.meta.env.VITE_API_URL);
        return { pendingPersonalisationTicket: await ticketApi.getPendingPersonalisationTicketByCode(params.shareCode, orderId)}
    },
    errorComponent: () => {
        return <div>Share code not found</div>
      },
  component: PendingPersonalisationTicket
})

function PendingPersonalisationTicket() {
    const { pendingPersonalisationTicket } = Route.useLoaderData();
    const { orderId } = Route.useSearch()
    const { currentLanguage: lang } = useLanguage();

    const ticketApi = new OrderApi(import.meta.env.VITE_API_URL);


    const [formState, setFormState] = useState<Record<string, object[] | string[]>>({
        orderDetails: [{first_name: pendingPersonalisationTicket.shared_to}]
    });

    const [formValid, setFormValid] = useState(false);
    const [ticket, setTicket] = useState<PendingPersonalisationTicketType>(pendingPersonalisationTicket);
    const [paymentMethod, setPaymentMethod] = useState<string|null>(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate({ from: '/ticket-share/$shareCode' })

    useEffect(() => {
        if(ticket?.payment_pending) {
            const checkPayment = async () => {
                const newTicket = await ticketApi.getPendingPersonalisationTicketByCode(ticket.share_code, orderId);
                setTicket(newTicket);
                if (newTicket.payment_pending) {
                    setTimeout(checkPayment, 500);
                }
            };
            setTimeout(checkPayment, 500);
        }
    }, [ticket?.payment_pending]);
    
    async function claimTicket(): Promise<void> {
        setLoading(true);
        try {
            const orderApi = new OrderApi(import.meta.env.VITE_API_URL);
            const res = await orderApi.claimTicket(
                pendingPersonalisationTicket.share_code, 
                formState,
                paymentMethod
            );

            if(res.type === "no_payment") {
                navigate({to: "/order/$orderId", params: {orderId: res.new_order_id}})
            } else {
                handleRedirect(res.url);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <StyleOverwrites shopStyle={pendingPersonalisationTicket.shopStyle}>
            <OrderPageShell event={pendingPersonalisationTicket.event} shopStyle={pendingPersonalisationTicket.shopStyle}>
                <div className='bg-white px-8 -mt-4 rounded-b-md' style={{
                    backgroundColor: shopVar('--shop-panelBackgroundColor'),
                    color: shopVar('--shop-textColor')
                }}>
                    <div className='w-full border-b pb-4 mb-4 border-dashed' style={{
                        borderColor: shopVar('--shop-borderColor')
                    }}>
                    </div>
                    <div className='pb-8'>
                        <h2 className='text-lg font-bold leading-7'><span className='underline'>{pendingPersonalisationTicket.shared_by}</span> invited you to {pendingPersonalisationTicket.event.name}</h2>
                        <ImportInfo className='py-4'>
                            {pendingPersonalisationTicket.should_pay && <ImportInfo.Item>You have to pay for this ticket in order to transfer it from {pendingPersonalisationTicket.shared_by} to you</ImportInfo.Item>}
                            <ImportInfo.Item>You need to claim the ticket before its yours</ImportInfo.Item>
                        </ImportInfo>
                        {pendingPersonalisationTicket.should_pay && <div className='flex justify-between items-center'>
                            <div className='flex flex-col gap-1'>
                                <span className='font-semibold'>{pendingPersonalisationTicket.productName}</span>
                                <span className='text-xs tracking-tight'>{Money.fromInteger(pendingPersonalisationTicket.price.amount, pendingPersonalisationTicket.price.currency).display(lang)} + {Money.fromInteger(pendingPersonalisationTicket.service_fee.amount, pendingPersonalisationTicket.service_fee.currency).display(lang)} Fee</span>
                            </div>
                            <div className='text-xl font-bold'>
                                {Money.fromInteger(pendingPersonalisationTicket.price.amount, pendingPersonalisationTicket.price.currency).add(Money.fromInteger(pendingPersonalisationTicket.service_fee.amount, pendingPersonalisationTicket.service_fee.currency)).display(lang)}
                            </div>
                        </div>}
                        {!pendingPersonalisationTicket.should_pay && <div className='flex justify-between items-center'>
                            <div className='flex flex-col gap-1'>
                                <span className='font-semibold'>{pendingPersonalisationTicket.productName}</span>
                            </div>
                        </div>}

                        <div className='pt-4'>
                            {!ticket.payment_pending && !ticket.paid && (
                            <CustomerDetailsForm
                                form={pendingPersonalisationTicket.form}
                                answers={formState}
                                errors={{}}
                                setAnswers={(answers) => setFormState(answers as Record<string, object[] | string[]>)}
                                setFormValid={setFormValid}
                            />
                            )}
                        </div>

                        {!ticket.payment_pending && !ticket.paid && ticket.should_pay && (
                            <div>
                            <SelectPaymentMethod 
                                className='py-6'
                                paymentMethods={pendingPersonalisationTicket.paymentMethods}
                                selectedMethod={paymentMethod}
                                onMethodChange={setPaymentMethod}
                                additionalFee={pendingPersonalisationTicket.paidShareFee ? calculateFee(
                                    pendingPersonalisationTicket.paidShareFee,
                                    Money.fromInteger(pendingPersonalisationTicket.price.amount, pendingPersonalisationTicket.price.currency),
                                    1
                                ) : undefined}
                                totalAmount={Money.fromInteger(pendingPersonalisationTicket.price.amount, pendingPersonalisationTicket.price.currency)}
                            />
                            <MainButton 
                                disabled={!formValid || !paymentMethod || loading}
                                loading={loading}
                                onClick={() => claimTicket()}
                                className="mt-4"
                            >
                                <div className="text-lg font-bold leading-7">Pay for ticket</div>
                            </MainButton>
                            </div>
                        )}

                        {ticket.paid && ticket.orderId !== null && (
                            <div>
                                <MainButton 
                                    onClick={() => navigate({to: "/order/$orderId", params: {orderId: ticket.orderId!}})}
                                    className="mt-4"
                                >
                                    <div className="text-lg font-bold leading-7">Go to ticket</div>
                                </MainButton>
                            </div>
                        )}

                        {ticket.paid && ticket.orderId === null && (
                            <div>
                                <h1>Ticket claimed by {ticket.shared_to}</h1>
                                <p>A mail has been sent to {ticket.shared_to} with the ticket details</p>
                            </div>
                        )}

                        {ticket.payment_pending && !ticket.paid && ticket.should_pay && (
                            <div>
                                <MainButton 
                                    disabled={true}
                                    className="mt-4"
                                >
                                    <div className="text-lg font-bold leading-7">Processing payment, please wait...</div>
                                </MainButton>
                            </div>
                        )}

                        {!ticket.should_pay && !ticket.paid && (
                            <MainButton 
                                disabled={!formValid || loading}
                                loading={loading}
                                onClick={() => claimTicket()}
                                className="mt-4"
                            >
                                <div className="text-lg font-bold leading-7">Claim ticket</div>
                            </MainButton>
                        )}
                    </div>
                </div>
            </OrderPageShell>
        </StyleOverwrites>)
}