import { useState, useEffect } from 'react';

interface CountdownTimerProps {
  targetTimestamp: number;
}

interface TimeLeft {
  hours: number;
  minutes: number;
  seconds: number;
}

function calculateTimeLeft(targetTimestamp: number): TimeLeft {
  const now = Math.floor(Date.now() / 1000); // Current time in seconds since epoch
  const difference = targetTimestamp - now;
  
  return {
    hours: Math.floor(difference / 3600),
    minutes: Math.floor((difference % 3600) / 60),
    seconds: Math.floor(difference % 60)
  };
}

function padNumber(num: number): string {
  return num.toString().padStart(2, '0');
}

export function CountdownTimer({ targetTimestamp }: CountdownTimerProps) {
  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft(targetTimestamp));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(targetTimestamp));
    }, 1000);

    return () => clearInterval(timer);
  }, [targetTimestamp]);

  return (
    <div className="flex justify-center gap-4 text-4xl font-mono mt-4">
      <div className="flex flex-col items-center">
        <div className="bg-gray-100 rounded-lg p-4 w-20 text-center">
          {padNumber(timeLeft.hours)}
        </div>
        <div className="text-sm mt-1 text-gray-600">hours</div>
      </div>
      <div className="flex flex-col items-center">
        <div className="bg-gray-100 rounded-lg p-4 w-20 text-center">
          {padNumber(timeLeft.minutes)}
        </div>
        <div className="text-sm mt-1 text-gray-600">minutes</div>
      </div>
      <div className="flex flex-col items-center">
        <div className="bg-gray-100 rounded-lg p-4 w-20 text-center">
          {padNumber(timeLeft.seconds)}
        </div>
        <div className="text-sm mt-1 text-gray-600">seconds</div>
      </div>
    </div>
  );
} 
