import { createFileRoute } from '@tanstack/react-router';
import { useEffect, useState, ChangeEvent, FormEvent } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import ReactMarkdown from 'react-markdown';
import { useToast } from '@/components/ui/use-toast';
import { Toaster } from '@/components/ui/toaster';
import { ulid } from 'ulidx';

interface FormConfig {
  id: string;
  eventName: string;
  instructions: string;
  max_amount_for_guests_per_request: number;
  disabled: boolean;
  disabled_reason: string;
}

interface FormData {
  name: string;
  email: string;
  places: number;
  requestedTrough: string;
  message: string;
}

interface ValidationErrors {
  [key: string]: string[];
}

export const Route = createFileRoute('/request-guestlist/$guest-list-form-id')({
  component: GuestListRequestForm,
});

function GuestListRequestForm() {
  const { toast } = useToast();
  const { 'guest-list-form-id': guestListFormId } = Route.useParams();
  const [formConfig, setFormConfig] = useState<FormConfig | null>(null);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({});
  const [formData, setFormData] = useState<FormData>({
    name: '',
    email: '',
    places: 1,
    requestedTrough: '',
    message: '',
  });

  useEffect(() => {
    fetchFormConfig();
  }, [guestListFormId]);

  const fetchFormConfig = async (): Promise<void> => {
    try {
      const response = await fetch(`${import.meta.env.VITE_API_URL}/guest-list-request-form/${guestListFormId}`, {
        headers: {
          'Accept': 'application/json',
        },
      });
      const data = await response.json();
      setFormConfig(data.data);
    } catch (error) {
      console.error('Failed to fetch form config:', error);
      setError('Failed to load form configuration');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setValidationErrors({});
    setSubmitting(true);
    
    try {
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/guest-list-request-form/${guestListFormId}/requests`,
        {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            request_id: ulid(),
            ...formData,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        if (response.status === 422) {
          setValidationErrors(errorData.errors || {});
          return;
        }
        throw new Error('Failed to submit form');
      }

      toast({
        title: "Request Submitted Successfully",
        description: "You will receive an email once your request is accepted.",
        className: "bg-zinc-900 border border-white/10 text-white backdrop-blur-sm",
      });

      // Clear form
      setFormData({
        name: '',
        email: '',
        places: 1,
        requestedTrough: '',
        message: '',
      });

    } catch (error) {
      console.error('Failed to submit form:', error);
      setError('Failed to submit form');
      toast({
        variant: "destructive",
        title: "Error",
        description: "Failed to submit form. Please try again.",
        className: "bg-red-900/50 border-red-500/30 text-white backdrop-blur-sm",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: keyof FormData) => {
    const value = field === 'places' ? parseInt(e.target.value) || 1 : e.target.value;
    setFormData({ ...formData, [field]: value });
  };

  if (loading) return (
    <div className="min-h-screen flex items-center justify-center bg-black">
      <div className="animate-pulse text-white/80">Loading...</div>
    </div>
  );
  if (error) return (
    <div className="min-h-screen flex items-center justify-center bg-black">
      <div className="text-red-400">{error}</div>
    </div>
  );
  if (!formConfig) return (
    <div className="min-h-screen flex items-center justify-center bg-black">
      <div className="text-white/80">Form not found</div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gradient-to-br from-black via-zinc-900 to-black text-white">
      <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-8">
        {/* Header section */}
        <div className="text-center mb-8 sm:mb-12">
          <h2 className="text-xs sm:text-sm font-medium text-white/70 uppercase tracking-[0.2em] mb-2 sm:mb-3">Guest List Request</h2>
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-3 sm:mb-4 text-white">
            {formConfig.eventName}
          </h1>
          
          {formConfig.instructions && (
            <div className="mb-6 sm:mb-8 prose prose-invert max-w-none prose-sm sm:prose-base">
              <ReactMarkdown>{formConfig.instructions}</ReactMarkdown>
            </div>
          )}
        </div>

        <div className="relative">
          {formConfig.disabled && (
            <div className="absolute inset-0 bg-black/80 backdrop-blur-sm z-10 rounded-xl sm:rounded-2xl flex items-center justify-center">
              <div className="bg-red-900/30 border border-red-500/30 text-red-200 px-4 sm:px-6 py-3 sm:py-4 rounded-lg backdrop-blur-sm max-w-md mx-4 text-center text-sm sm:text-base">
                {formConfig.disabled_reason}
              </div>
            </div>
          )}

          <form 
            onSubmit={handleSubmit} 
            className={`space-y-6 sm:space-y-8 backdrop-blur-sm bg-white/5 p-4 sm:p-6 md:p-8 rounded-xl sm:rounded-2xl border border-white/10 ${
              formConfig.disabled ? 'opacity-50' : ''
            }`}
          >
            <div>
              <Label htmlFor="name" className="text-white/80 text-sm sm:text-base">Name</Label>
              <Input
                id="name"
                value={formData.name}
                onChange={(e) => handleInputChange(e, 'name')}
                className={`bg-black/50 border-white/10 focus:border-white/30 focus:ring-white/20 mt-1.5 sm:mt-2 text-sm sm:text-base ${
                  validationErrors.name ? 'border-red-500' : ''
                }`}
              />
              {validationErrors.name && (
                <p className="text-red-400 text-xs sm:text-sm mt-1">{validationErrors.name[0]}</p>
              )}
            </div>

            <div>
              <Label htmlFor="email" className="text-white/80 text-sm sm:text-base">Email</Label>
              <Input
                id="email"
                type="email"
                value={formData.email}
                onChange={(e) => handleInputChange(e, 'email')}
                className={`bg-black/50 border-white/10 focus:border-white/30 focus:ring-white/20 mt-1.5 sm:mt-2 text-sm sm:text-base ${
                  validationErrors.email ? 'border-red-500' : ''
                }`}
              />
              {validationErrors.email && (
                <p className="text-red-400 text-xs sm:text-sm mt-1">{validationErrors.email[0]}</p>
              )}
            </div>

            <div>
              <Label htmlFor="places" className="text-white/80 text-sm sm:text-base">Number of Guests</Label>
              <Input
                id="places"
                type="number"
                min="1"
                max={formConfig.max_amount_for_guests_per_request}
                value={formData.places}
                onChange={(e) => handleInputChange(e, 'places')}
                className={`bg-black/50 border-white/10 focus:border-white/30 focus:ring-white/20 mt-1.5 sm:mt-2 text-sm sm:text-base ${
                  validationErrors.places ? 'border-red-500' : ''
                }`}
              />
              {validationErrors.places && (
                <p className="text-red-400 text-xs sm:text-sm mt-1">{validationErrors.places[0]}</p>
              )}
            </div>

            <div>
              <Label htmlFor="requestedTrough" className="text-white/80 text-sm sm:text-base">Referred by</Label>
              <Input
                id="requestedTrough"
                value={formData.requestedTrough}
                onChange={(e) => handleInputChange(e, 'requestedTrough')}
                className={`bg-black/50 border-white/10 focus:border-white/30 focus:ring-white/20 mt-1.5 sm:mt-2 text-sm sm:text-base ${
                  validationErrors.requestedTrough ? 'border-red-500' : ''
                }`}
              />
              {validationErrors.requestedTrough && (
                <p className="text-red-400 text-xs sm:text-sm mt-1">{validationErrors.requestedTrough[0]}</p>
              )}
            </div>

            <div>
              <Label htmlFor="message" className="text-white/80 text-sm sm:text-base">Additional Notes (optional)</Label>
              <textarea
                id="message"
                value={formData.message}
                onChange={(e) => handleInputChange(e, 'message')}
                className={`w-full min-h-[80px] sm:min-h-[100px] px-3 sm:px-4 py-2 sm:py-3 mt-1.5 sm:mt-2 bg-black/50 border rounded-lg focus:ring-white/20 focus:border-white/30 text-sm sm:text-base ${
                  validationErrors.message ? 'border-red-500' : 'border-white/10'
                }`}
              />
              {validationErrors.message && (
                <p className="text-red-400 text-xs sm:text-sm mt-1">{validationErrors.message[0]}</p>
              )}
            </div>

            <Button 
              type="submit" 
              disabled={submitting}
              className="w-full bg-white hover:bg-white/90 text-black font-semibold py-4 sm:py-6 rounded-lg transition-all duration-300 text-sm sm:text-base"
            >
              {submitting ? (
                <>
                  <svg className="animate-spin -ml-1 mr-2 sm:mr-3 h-4 w-4 sm:h-5 sm:w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Processing Request...
                </>
              ) : (
                'Submit Request'
              )}
            </Button>
          </form>
        </div>

        <div className="mt-8 sm:mt-16 pt-4 sm:pt-8 border-t border-white/10 text-center">
          <a href="https://fastlane.events" target="_blank" rel="noopener noreferrer" className="inline-block opacity-75 hover:opacity-100 transition-opacity">
            <p className="text-xs sm:text-sm text-white/60 mb-2 sm:mb-3">Powered by Fastlane</p>
          </a>
        </div>
      </div>
      <Toaster />
    </div>
  );
}
