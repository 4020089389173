import { Ticket } from '@/components/order/Ticket';
import { OrderApi, TicketStatus, TicketType } from '@/lib/OrderApi'
import type { Order } from '@/lib/OrderApi'
import { Await, createFileRoute, defer } from '@tanstack/react-router'
import OrderPageShell from '@/components/order/OrderPageShell';
import { ImportInfo } from '@/components/order/ImportInfo';
import { useEffect, useCallback, useState } from 'react';
import { StyleOverwrites } from '@/providers/StyleOverwrites';

// Loading skeleton component for tickets
const TicketSkeleton = () => (
  <div className="animate-pulse bg-[var(--shop-panelBackgroundColor)] rounded-xl p-4 mb-4">
    <div className="h-6 bg-gray-200 rounded w-3/4 mb-4"></div>
    <div className="space-y-3">
      <div className="h-4 bg-gray-200 rounded w-1/2"></div>
      <div className="h-4 bg-gray-200 rounded w-2/3"></div>
      <div className="h-4 bg-gray-200 rounded w-1/3"></div>
    </div>
  </div>
);

// Loading state component
const LoadingState = () => (
  <div className='bg-gradient-to-b from-[#acb0ac] to-[#777877] sm:p-10 min-h-screen font-sans'>
    <div className='max-w-[640px] mx-auto'>
      <div className='bg-[var(--shop-panelBackgroundColor)] text-[var(--shop-textColor)] rounded-md rounded-bl-md rounded-br-md'>
        <div className='text-center text-lg font-bold py-4'>
          Loading order...
        </div>
      </div>
      <div className='px-4 sm:px-0 pb-40 space-y-4 mt-4'>
        <TicketSkeleton />
        <TicketSkeleton />
        <TicketSkeleton />
      </div>
    </div>
  </div>
);

const OrderNotFound = () => (
  <div className='bg-gradient-to-b from-[#9bb7ff] to-[#a7dbc7] sm:p-10 min-h-screen font-sans'>
    <div className='max-w-[640px] mx-auto'>
      <div className='bg-[var(--shop-panelBackgroundColor)] text-[var(--shop-textColor)] rounded-md rounded-bl-md rounded-br-md'>
        <div className='text-center text-lg font-bold pt-4'>Order not found</div>
        <div className='text-center text-sm py-4 px-4'>
          We couldn't find the order you are looking for. 
          <br /><br />
          <p className='font-bold'>If you just purchased tickets, it could be that our systems are busy. Please wait a few minutes and try again. You should receive an email with your tickets shortly.</p>
          <br />
          If you believe this is an error, please contact our support. <a href="mailto:ticketing@fastlane.events" className="text-[var(--shop-ctaButtonColor)]">ticketing@fastlane.events</a>
        </div>
      </div>
    </div>
  </div>
)

export const loadOrder = async (orderId: string) => {
  const ticketApi = new OrderApi(import.meta.env.VITE_API_URL);
  let order;
  let attempts = 0;
  const maxAttempts = 30;
  const interval = 1000; // 1 second

  while (attempts < maxAttempts) {
    try {
      order = await ticketApi.getOrder(orderId);
      break; // If successful, exit the loop
    } catch (error) {
      attempts++;
      if (attempts >= maxAttempts) {
        console.error(error);
        return null;
      }
      await new Promise(resolve => setTimeout(resolve, interval));
    }
  }

  return order;
}

type OrderLoaderData = {
  order: Promise<Order | null | undefined>
}

type OrderParams = {
  orderId: string
}

export const Route = createFileRoute('/order/$orderId')({
  loader: async ({ params }: { params: OrderParams }): Promise<OrderLoaderData> => {
    return { order: defer(loadOrder(params.orderId)) }
  },
  component: Order,
})

// const getWarnings = (order: Order) => {
//   let warnings = [];
//   if (order.tickets.some(ticket => ticket.ticketStatus === TicketStatus.claimable)) {
//     warnings.push(<div>You have claimable tickets in your order. Please claim them before you can resell them.</div>);
//   }
//   return warnings;
// }

function getImportantInfo(data: Order) {
  const importantInfo = [];
  if (data.tickets.some(ticket => 
    ticket.ticketStatus === TicketStatus.available && 
    ticket.ticketType === TicketType.EntranceTicket && 
    'sealed' in ticket && ticket.sealed
  )) {
    importantInfo.push("Some of your tickets are sealed. They will be available to you on the <b>release date</b>.");
  }

  if (data.tickets.some(ticket => ticket.ticketStatus === TicketStatus.claimable)) {
    importantInfo.push("Tickets for your group need to be <b>shared</b> with them");
  }

  if (data.tickets.some(ticket => 'canResell' in ticket && ticket.canResell)) {
    importantInfo.push("Tickets can be resold on the event's <b>marketplace</b>");

    // Find first resellable ticket to get the max percentage
    const resellableTicket = data.tickets.find(ticket => 
      'canResell' in ticket && 
      ticket.canResell && 
      'maxResellPercentage' in ticket
    );
    
    if (resellableTicket && 'maxResellPercentage' in resellableTicket) {
      importantInfo.push(`You can <b>only</b> resell your tickets via our marketplace (max +${resellableTicket.maxResellPercentage}%)`);
    }
  }

  return importantInfo;
}

function Order() {
  const { order } = Route.useLoaderData() as OrderLoaderData
  const { orderId } = Route.useParams() as OrderParams
  const [orderData, setOrderData] = useState<Order | null>(null)
  
  useEffect(() => {
    order.then((data: Order | null | undefined) => {
      if (data) setOrderData(data)
    })
  }, [order])
  
  const reloadOrder = useCallback(async () => {
    const newOrder = await loadOrder(orderId)
    if (newOrder) {
      setOrderData(newOrder)
    }
  }, [orderId]);

  // Check for sealed tickets with passed availability dates
  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    const checkSealedTickets = (data: Order) => {
      const hasAnySealed = data.tickets.some(ticket => 
        ticket.ticketStatus === TicketStatus.available && 
        'sealed' in ticket && ticket.sealed && 
        ticket.ticketType === TicketType.EntranceTicket
      );

      // Clear existing interval if no more sealed tickets
      if (!hasAnySealed && intervalId) {
        clearInterval(intervalId);
        intervalId = null;
        return;
      }

      // Only set up new interval if we don't have one already
      if (hasAnySealed && !intervalId) {
        intervalId = setInterval(() => {
          const now = new Date();
          const hasPassedSealedTicket = data.tickets.some(ticket => 
            ticket.ticketStatus === TicketStatus.available && 
            ticket.ticketType === TicketType.EntranceTicket &&
            'sealed' in ticket && ticket.sealed && 
            'availableFrom' in ticket && ticket.availableFrom && 
            new Date(ticket.availableFrom.split('[')[0]) <= now
          );

          if (hasPassedSealedTicket) {
            reloadOrder();
          }
        }, 2000);
      }
    };

    if (orderData) {
      checkSealedTickets(orderData);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [orderData, reloadOrder]);

  return (
    <Await promise={order} fallback={<LoadingState />}>
      {(data) => {
        const displayData = orderData || data;
        if (!displayData || displayData == null) return <OrderNotFound />;
        return (
          <StyleOverwrites shopStyle={displayData.shopStyle}>
            <OrderPageShell event={displayData.event} shopStyle={displayData.shopStyle}>
              {getImportantInfo(displayData).length > 0 && (
                <div className='bg-[var(--shop-panelBackgroundColor)] text-[var(--shop-textColor)] rounded-md mt-4'>
                  <ImportInfo className='px-8 py-4'>
                    <ImportInfo.Title>Import Information</ImportInfo.Title>
                    {getImportantInfo(displayData).map((item, index) => (
                      <ImportInfo.Item key={index}>{item}</ImportInfo.Item>
                    ))}
                  </ImportInfo>
                </div>
              )}
              <div className='px-4 sm:px-0 pb-40'>
                {displayData.tickets.map((ticket) => (
                  <Ticket orderId={displayData.orderId} ticket={ticket} key={ticket.ticketId} answers={displayData.formAnswers} triggeredReload={reloadOrder} marketplaceUrl={displayData.marketplaceUrl}/>
                ))}
              </div>
            </OrderPageShell>
          </StyleOverwrites>
        );
      }}
    </Await>
  )
}
