import React from 'react';
import { Header } from '@/components/header/Header';
import { EventProvider } from '@/providers/EventProvider';
import { Event, ShopStyle } from '@/lib/TicketApi';
import { StyleOverwrites } from '@/providers/StyleOverwrites';

interface OrderPageShellProps {
  event: Event;
  children: React.ReactNode;
  shopStyle?: ShopStyle;
}

const OrderPageShell: React.FC<OrderPageShellProps> = ({ event, children, shopStyle }) => {
  const hasBackgroundImage = shopStyle?.pageBackgroundImage;
  const style = {
    background: hasBackgroundImage
      ? `url(${shopStyle?.pageBackgroundImage}) center/cover fixed no-repeat, linear-gradient(to bottom, ${shopStyle?.pageBackgroundFromColor || '#ffffff'}, ${shopStyle?.pageBackgroundToColor || '#ffffff'})`
      : `linear-gradient(to bottom, ${shopStyle?.pageBackgroundFromColor || '#ffffff'}, ${shopStyle?.pageBackgroundToColor || '#ffffff'})`
  };

  return (
    <div className="min-h-screen sm:p-10 font-sans" style={style}>
      <StyleOverwrites shopStyle={shopStyle}>
        <EventProvider event={event}>
          <div className='max-w-[640px] mx-auto pb-[20px]'>
            <div className='bg-[var(--shop-panelBackgroundColor)] text-[var(--shop-textColor)] rounded-3xl rounded-bl-md rounded-br-md'>
              <Header />
            </div>
            {children}
          </div>
        </EventProvider>
      </StyleOverwrites>
    </div>
  );
};

export default OrderPageShell;
