import React, { createContext, useContext, useState, useEffect, ReactNode, useRef } from 'react';
import { Shop, TicketApi } from '@/lib/TicketApi';
import { eventEmitter, ReloadShopConfigPayload } from '@/lib/eventEmitter';
import * as m from "@/paraglide/messages.js"
import { onSetLanguageTag } from '@/paraglide/runtime';
import { useLanguage } from './LanguageProvider';
import { StyleOverwrites } from './StyleOverwrites';
import { useTracking } from '@/tracking/context';

interface ShopContextType {
  shop: Shop;
  isLoading: boolean;
  shopSlug: string;
  baseUrl: string;
  isWidget: boolean;
  isStandalone: boolean;
  error: string | null;
  selectedEventSlug: string | undefined;
  initialEventSlug: string | undefined;
  setSelectedEventSlug: (eventSlug: string | undefined) => void;
}

const ShopContext = createContext<ShopContextType | undefined>(undefined);

interface ShopProviderProps {
  shop?: Shop;
  shopSlug: string;
  baseUrl: string;
  children: ReactNode;
  isWidget?: boolean;
  isStandalone?: boolean;
  initialEventSlug?: string;
  usePageBackground?: boolean;
}

export interface ShopParams {
  language: string;
  unlockedProductIds: string[];
}

const PageBackground: React.FC<{ shop: Shop; children: ReactNode }> = ({ shop, children }) => {
  const { isWidget } = useShop();
  const hasBackgroundImage = !!shop.style.pageBackgroundImage;
  const style = {
    background: hasBackgroundImage
      ? `url(${shop.style.pageBackgroundImage}) center/cover fixed no-repeat, linear-gradient(to bottom, ${shop.style.pageBackgroundFromColor || '#ffffff'}, ${shop.style.pageBackgroundToColor || '#ffffff'})`
      : `linear-gradient(to bottom, ${shop.style.pageBackgroundFromColor || '#ffffff'}, ${shop.style.pageBackgroundToColor || '#ffffff'})`
  };
  
  return (
    <div className="min-h-screen sm:p-10" style={style}>
      <div className={`max-w-[800px] mx-auto ${isWidget ? 'rounded-md' : 'rounded-3xl'} pb-6 shadow-2xl mb-[20px]`} style={{
        backgroundColor: shop.style.panelBackgroundColor || '#ffffff',
      }}>
        {children}
      </div>
    </div>
  );
};

export const ShopProvider: React.FC<ShopProviderProps> = ({ 
  shopSlug, 
  children, 
  shop: initialShop, 
  baseUrl, 
  isWidget = false,
  isStandalone = false,
  initialEventSlug,
  usePageBackground = false 
}) => {
  const [shop, setShop] = useState<Shop | null>(initialShop ?? null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedEventSlug, setSelectedEventSlug] = useState<string | undefined>(initialEventSlug);
  const [cachedShops, setCachedShops] = useState<Record<string, Shop>>({});
  const [loadedTrackers, setLoadedTrackers] = useState<string[]>([]);
  const isInitialMount = useRef(true);
  const trackedPageViews = useRef<Set<string>>(new Set());

  const trackingProvider = useTracking();
  const { trackEvent } = useTracking();
  const { currentLanguage: lang } = useLanguage();

  const [shopParams, setShopParams] = useState<ShopParams>({
    language: lang,
    unlockedProductIds: [],
  });

  const fetchShopConfig = async (params: ShopParams) => {
    const cacheKeyForShop = `${shopSlug}-${params.language}`;
    
    // Only check cache if there are no unlocked products
    if (params.unlockedProductIds.length === 0 && cachedShops[cacheKeyForShop]) {
      setShop(cachedShops[cacheKeyForShop]);
      return;
    }

    const MAX_RETRIES = 5;
    const INITIAL_DELAY = 500; // 500ms initial delay

    let lastError: unknown;
    
    for (let attempt = 0; attempt < MAX_RETRIES; attempt++) {
      try {
        if (attempt === 0) {
          setIsLoading(true);
        }

        const ticketApi = new TicketApi(baseUrl, shopSlug);
        const shop = await ticketApi.loadShop(params.language, params.unlockedProductIds);
        setShop(shop);
        
        // Only cache if there are no unlocked products
        if (params.unlockedProductIds.length === 0) {
          setCachedShops(prev => ({...prev, [cacheKeyForShop]: shop}));
        }

        setIsLoading(false);
        return; // Success, exit the retry loop
      } catch (err) {
        lastError = err;
        console.warn(`Failed to load shop config (attempt ${attempt + 1}/${MAX_RETRIES}):`, err);
        
        if (attempt < MAX_RETRIES - 1) {
          // Calculate delay with exponential backoff: 500ms, 1000ms, 2000ms, 4000ms...
          const delay = INITIAL_DELAY * Math.pow(2, attempt);
          await new Promise(resolve => setTimeout(resolve, delay));
        }
      }
    }

    // If we get here, all retries failed
    setIsLoading(false);
    setError(lastError instanceof Error ? lastError.message : 'Failed to load shop after multiple attempts');
  };

  // Combined effect for initial load and language changes
  useEffect(() => {
    fetchShopConfig(shopParams).then(() => {
      // Only track page view if we haven't tracked it for this shop yet
      const pageViewKey = `${shopSlug}-${window.location.pathname}`;
      if (!trackedPageViews.current.has(pageViewKey)) {
        trackedPageViews.current.add(pageViewKey);
        trackEvent({
          type: 'PAGE_VIEW',
          payload: {
            shopId: shopSlug,
            path: window.location.pathname,
            title: document.title,
            referrer: document.referrer,
            params: Object.fromEntries(new URLSearchParams(window.location.search))
          }
        });
      }
    });

    // Set up language change listener
    const languageChangeHandler = (newLanguageTag: string) => {
      
      if (isInitialMount.current) {
        isInitialMount.current = false;
        return;
      }

      const newParams = {
        ...shopParams,
        language: newLanguageTag,
      };
      setShopParams(newParams);
      fetchShopConfig(newParams);
    };

    onSetLanguageTag(languageChangeHandler);

    // Cleanup
    return () => {
      // If onSetLanguageTag provides a cleanup method, use it here
    };
  }, [shopSlug, trackEvent]); // Added trackEvent to dependencies

  useEffect(() => {
    if (shop?.conversionTracking?.trackers) {
      for (const tracker of shop.conversionTracking.trackers) {
        if (!loadedTrackers.includes(tracker.id)) {
          trackingProvider.addPixelConfig(tracker);
          setLoadedTrackers(prev => [...prev, tracker.id]);
        }
      }
    }
  }, [shop]);

  useEffect(() => {
    const handleReloadShopConfig = (payload: ReloadShopConfigPayload) => {
      if (payload.shopSlug !== shopSlug) {
        return;
      }

      const hasLanguageChanged = payload.language !== undefined && payload.language !== shopParams.language;
      const hasUnlockedProductsChanged = payload.unlockedProductIds !== undefined && 
        (payload.unlockedProductIds.length !== shopParams.unlockedProductIds.length || 
         payload.unlockedProductIds.some(id => !shopParams.unlockedProductIds.includes(id)));

      if (!hasLanguageChanged && !hasUnlockedProductsChanged) {
        return;
      }

      const newShopParams = {
        language: payload.language ?? shopParams.language,
        unlockedProductIds: payload.unlockedProductIds ?? shopParams.unlockedProductIds,
      };

      setShopParams(newShopParams);
      fetchShopConfig(newShopParams);
    };

    eventEmitter.on('RELOAD_SHOP_CONFIG', handleReloadShopConfig);
    return () => eventEmitter.off('RELOAD_SHOP_CONFIG', handleReloadShopConfig);
  }, [shopParams]); // Add shopParams as dependency

  // Add effect to handle automatic event selection when there's only one event
  useEffect(() => {
    if (shop?.events.length === 1 && !selectedEventSlug) {
      setSelectedEventSlug(shop.events[0].slug);
    }
  }, [shop?.events, selectedEventSlug]);

  // Add event listeners for shop-open and shop-close
  useEffect(() => {
    const handleShopOpen = (e: Event) => {
      const customEvent = e as CustomEvent;
      if (customEvent.detail.shopSlug === shopSlug) {
        setSelectedEventSlug(customEvent.detail.eventSlug || undefined);
      }
    };

    const handleShopClose = (e: Event) => {
      const customEvent = e as CustomEvent;
      if (customEvent.detail.shopSlug === shopSlug) {
        setSelectedEventSlug(undefined);
      }
    };

    window.addEventListener('shop-open', handleShopOpen);
    window.addEventListener('shop-close', handleShopClose);

    return () => {
      window.removeEventListener('shop-open', handleShopOpen);
      window.removeEventListener('shop-close', handleShopClose);
    };
  }, [shopSlug]);

  if (shop === null) {
    return <div>{m.loading()}...</div>;
  }

  const value = {
    shop,
    isLoading,
    error,
    shopSlug,
    baseUrl,
    isWidget: isWidget ?? false,
    isStandalone: isStandalone ?? false,
    selectedEventSlug,
    initialEventSlug,
    setSelectedEventSlug,
  };

  return (
    <ShopContext.Provider value={value}>
      <StyleOverwrites shopStyle={shop.style}>
        {usePageBackground ? (
          <PageBackground shop={shop}>
            {children}
          </PageBackground>
        ) : (
          children
        )}
      </StyleOverwrites>
    </ShopContext.Provider>
  );
};

export const useShop = () => {
  const context = useContext(ShopContext);
  if (context === undefined) {
    throw new Error('useShop must be used within a ShopProvider');
  }
  return context;
};

export const useShopWhenAvailable = () => {
  const context = useContext(ShopContext);
  if (context === undefined) {
    return null;
  }
  return context;
};